(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('printerService', printerService);

    printerService.$inject = ['appSettings', 'settingsService', 'receiptTemplateService', 'bluetoothService', 'locale'];

    var msgLength = 20;
    var printCharacteristic;
    var isPrinting = false;
    var dataIndex = 0;

    function printerService(appSettings, settingsService, receiptTemplateService, bluetoothService, locale) {

        var service = {
            printReceipt: printReceipt,
            printReceiptConsole: printReceiptConsole
        };

        return service;

        // global
        // ReSharper disable once JsUnreachableCode
        var printData;

        function printReceipt(routeStop) {
            return new Promise((resolve, reject) => {
                if (!isPrinting) {
                    isPrinting = true;
                    settingsService.getActiveDriver()
                        .then(driver => {
                            var userData = {
                                routeStop,
                                driver
                            }
                            return createReceipt(userData);
                        })
                        .then(receipt => {
                            printData = receipt;
                            return bluetoothService.getZQ510Printer();
                        })
                        .catch((err) => {
                            isPrinting = false;
                            return reject(locale.error_createReceipt + err);
                        })
                        .then(printer => {
                            if (printer) {
                                return getPrintCharacteristic(printer);
                            } else {
                                isPrinting =false;
                                return reject(locale.error_printerUnavailable);
                            }
                        })
                        .catch(() => {
                            isPrinting =false;
                            reject(locale.error_connectingPrinter);
                        })
                        .then(printChar => {
                            printCharacteristic = printChar;
                            sendPrintData()
                                .then(() => {
                                    resolve();
                                })
                                .catch(err => {
                                    reject(locale.error_sendingData + err);
                                });
                        })
                        .catch(err => {
                            reject(locale.error_establishingConnection);
                        })
                        .finally(() => {
                            isPrinting = false;
                        });
                } else {
                    reject(locale.error_printerIsBusy);
                }
            });
        }

        function printReceiptConsole(routeStop) {
            return new Promise((resolve, reject) => {
                if (!isPrinting) {
                    isPrinting = true;
                    settingsService.getActiveDriver()
                        .then(driver => {
                            var userData = {
                                routeStop,
                                driver
                            }
                            return createReceipt(userData, true);
                        })
                        .then(receipt => {
                            printData = receipt;
                            resolve(true);
                        })
                        .catch((err) => {
                            return reject(locale.error_createReceipt + " " + err);
                        })
                        .finally(() => {
                            isPrinting = false;
                        });
                } else {
                    reject(locale.error_printerIsBusy);
                }
            });
        }

        function getPrintCharacteristic(printer) {
            var toPrinterUuid = appSettings.ZQ510.ToPrinter;
            return new Promise((resolve, reject) => {
                printer.gatt.connect()
                    .then(server => {
                        return server.getPrimaryServices();
                    })
                    .then(services => {
                        return services[0].getCharacteristics();
                    })
                    .then(characteristics => {
                        if (characteristics.length > 0) {

                            var printCharacteristics = _.filter(characteristics, c => c.uuid === toPrinterUuid);
                            var printCharacteristic = printCharacteristics && printCharacteristics.length
                                ? printCharacteristics[0]
                                : null;
                            resolve(printCharacteristic);
                        }
                    }).catch(err => {
                        reject();
                        console.log('Error in getPrintCharacteristic: ' + err);
                    });
            });
        }

        function createReceipt(userData, asString) {
            return new Promise((resolve, reject) => {
                receiptTemplateService.getReceiptTemplate()
                    .then(template => {
                        var mergedTemplateResult = receiptTemplateService.createPrintableReceipt(template, userData);
                        if (mergedTemplateResult.errorMsg) {
                            reject(mergedTemplateResult.errorMsg);
                        } else {
                            if (asString)
                                resolve(mergedTemplateResult.data);
                            else {
                                var byteTemplate = toByteArray(mergedTemplateResult.data);
                                resolve(byteTemplate);
                            }
                        }
                    });
            });
        }

        function sendNextPrintDataBatch(resolve, reject) {
            // Can only write MSG_LENGTH bytes at a time to the characteristic
            // Need to send the print data in MSG_LENGTH byte batches
            if (dataIndex + msgLength < printData.length) {
                printCharacteristic.writeValue(printData.slice(dataIndex, dataIndex + msgLength)).then(() => {
                    dataIndex += msgLength;
                    sendNextPrintDataBatch(resolve, reject);
                })
                    .catch(error => reject(error));
            } else {
                // Send the last bytes
                if (dataIndex < printData.length) {
                    printCharacteristic.writeValue(printData.slice(dataIndex, printData.length)).then(() => {
                        resolve();
                    })
                        .catch(error => reject(error));
                } else {
                    resolve();
                }
            }
        }

        function sendPrintData() {
            if (!printCharacteristic) {
                return new Promise((resolve, reject) => { reject(locale.error_addressError); });
            }
            dataIndex = 0;
            return new Promise(function (resolve, reject) {
                sendNextPrintDataBatch(resolve, reject);
            });
        }

        function toByteArray(str) {
            var bytes = [];
            for (var i = 0; i < str.length; ++i) {
                var code = str.charCodeAt(i);
                bytes.push(code);
            }
            return new Uint8Array(bytes);
        }
    }
})();

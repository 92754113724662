(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('lifecycleService', lifecycleService);

    lifecycleService.$inject = [
        '$rootScope',
        '$interval',
        'cacheService'
    ];

    function lifecycleService($rootScope, $interval, cacheService) {
        var timer = null;
        const cachePrefix = '_orders_';
        const checkRouteValidInterval = 5000;
        var _notifyCallback;
        var _orderId;

        var lifecycleService = {
            startOrderValidation: startOrderValidation,
            stopOrderValidation: stopOrderValidation
        };

        return lifecycleService;

        function startOrderValidation(userType, orderId, notifyCallback) {
            if ($rootScope.userType && $rootScope.userType === userType && orderId) {
                _orderId = orderId;
                _notifyCallback = notifyCallback;
                if (timer === null) {
                    checkRouteValid()
                        .then(() => {
                        if (checkRouteValidInterval) {
                            timer = $interval(checkRouteValid, checkRouteValidInterval);
                        }
                    });
                }
            }
        }

        function stopOrderValidation() {
            $interval.cancel(timer);
            timer = null;
        }

        function checkRouteValid() {
            return cacheService.get(cachePrefix).then((orders) => {
                var order = _.find(orders, { orderId: Number(_orderId) });
                if (order && order.orderDate) {
                    var isOld = moment().startOf('day') > moment(order.orderDate).startOf('day');
                    if (isOld) {
                        _notifyCallback();
                    }
                }
            });
        }
    }
})();

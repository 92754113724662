(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('deviationService', deviationService);

    deviationService.$inject = ['$q', 'cacheService'];

    function deviationService($q, cacheService) {
        var cachePrefix = '_deviation_';

        var deviationService = {
            getImageFromCache: getImageFromCache,
            getImagesFromCache: getImagesFromCache,
            saveImageToCache: saveImageToCache,
            removeImageFromCache: removeImageFromCache,
            sortDeviations: sortDeviations,
            removeImageFromCacheByTimeStamp: removeImageFromCacheByTimeStamp
        };

        return deviationService;

        
        function sortDeviations(deviations) {
            return _.sortBy(deviations, [function (deviation) { return deviation.rank === -1; }, 'rank', 'text']);
        };

        function getImageFromCache(orderId, agreementLineIds) {
            var deferred = $q.defer();

            getImagesFromCache(orderId).then(function (cachedImages) {
                var image = _.find(cachedImages, function (cachedImage) {
                    // agreement line id arrays are exclusive (intersections are empty)
                    return cachedImage.agreementLineIds.length === agreementLineIds.length &&
                        !_.difference(cachedImage.agreementLineIds, agreementLineIds).length;
                });
                deferred.resolve(image);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getImagesFromCache(orderId, routeStopId) {
            var deferred = $q.defer();

            cacheService.get(cachePrefix + orderId).then(function (cachedImages) {
                var routeStopImages = routeStopId ?
                    _.filter(cachedImages, { 'routeStopId': Number(routeStopId) }) :
                    cachedImages;

                deferred.resolve(routeStopImages);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function saveImageToCache(orderId, routeStopId, agreementLineIds, imageHash) {
            var deferred = $q.defer();

            var image = {
                timestamp: moment().format(),
                agreementLineIds: agreementLineIds,
                routeStopId: routeStopId,
                imageHash: imageHash
            };

            cacheService.appendTo(cachePrefix + orderId, image)
                .then(function () {
                    deferred.resolve();
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function removeImageFromCacheByTimeStamp(orderId, timestamp) {
            var deferred = $q.defer();

            cacheService.has(cachePrefix + orderId)
                .then(function (exists) {
                    if (exists && timestamp) {
                       return cacheService.removeFromBy(cachePrefix + orderId, null, 'timestamp', timestamp, 'eq');
                    }
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function removeImageFromCache(orderId, agreementLineIds) {
            var deferred = $q.defer();

            cacheService.has(cachePrefix + orderId)
                .then(function (exists) {
                    if (exists) {
                        if (agreementLineIds)
                            return cacheService.removeFromBy(cachePrefix + orderId, null, 'agreementLineIds', agreementLineIds[0], 'in');
                        else
                            return cacheService.remove(cachePrefix + orderId);
                    }
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('networkService', networkService);

    networkService.$inject = [
        '$q',
        '$http',
        'serviceUrls'
    ];

    function networkService(
        $q,
        $http,
        serviceUrls
    ) {
        let isPingRunning = false,
            isOnlineDeferred = null;
        let networkService = {
            isOnline
        };

        return networkService;

        function isOnline() {
            if (!isPingRunning) {
                isOnlineDeferred = $q.defer();
                isPingRunning = true;

                $http.get(serviceUrls.ping, { timeout: 2000 })
                    .then(() => isOnlineDeferred.resolve(true))
                    .catch(() => isOnlineDeferred.resolve(false))
                    .finally(() => isPingRunning = false);
            }

            return isOnlineDeferred.promise;
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').config(routeConfig);

    routeConfig.$inject = ['$stateProvider', '$urlRouterProvider', 'appVersion'];

    function routeConfig($stateProvider, $urlRouterProvider, appVersion) {
        var navbarView = {
            templateUrl: 'app/navbar/navbar.html',
            controller: 'NavbarController'
        };

        var footerView = {
            templateUrl: 'app/footer/footer.html',
            controller: 'FooterController'
        };

        var infoView = {
            templateUrl: 'app/info/info.html',
            controller: 'InfoController'
        };

        $urlRouterProvider.otherwise('/login');

        $stateProvider
            .state('login', {
                url: '/login',
                params: {
                    returnState: '',
                    returnParams: {}
                },
                views: {
                    'main': {
                        templateUrl: 'app/login/login-view.html',
                        controller: 'LoginController'
                    }
                }
            }).state('error', {
                url: '/error',
                views: {
                    'main': {
                        templateUrl: 'app/error/error-view.html',
                        controller: 'ErrorController'
                    },
                    'navbar': navbarView,
                    'footer': footerView
                }
            })
            .state('main', {
                url: '/main',
                views: {
                    'main': {
                        templateUrl: 'app/main/main-view.html',
                        controller: 'MainController'
                    },
                    'info': infoView,
                    'navbar': navbarView,
                    'footer': footerView
                }
            })
            .state('main.orders', {
                url: '/orders',
                templateUrl: 'app/orders/orders-view.html',
                controller: 'OrdersController'
            }).state('main.routeStopsContainer', {
                abstract: true,
                templateUrl: 'app/routeStops/route-stops-view.html',
                controller: 'RouteStopsController'
            }).state('main.routeStops', {
                parent: 'main.routeStopsContainer',
                url: '/route-stops?orderId&sortBy&splitView&status&reloadReportedRouteStops',
                views: {
                    'content': {
                        templateUrl: 'app/routeStops/route-stops-list-view.html',
                        controller: 'RouteStopsListController'
                    },
                    'right-pane': {
                        templateUrl: 'app/map/map-view.html',
                        controller: 'MapController'
                    }
                }
            }).state('main.completedRouteStops', {
                url: '/completed-route-stops?orderId',
                templateUrl: 'app/routeStops/completed-route-stops-view.html',
                controller: 'CompletedRouteStopsController'
            }).state('main.map', {
                url: '/map?orderId&completed',
                templateUrl: 'app/map/map-view.html',
                controller: 'MapController'
            }).state('main.webSocket', {
                url: '/web-socket',
                templateUrl: 'app/webSocket/web-socket-view.html',
                controller: 'WebSocketController'
            });
    }
})();

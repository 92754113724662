(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('trace', trace);

    trace.$inject = ['$q', '$http', '$rootScope', '$log', 'cacheService', 'appSettings', 'serviceUrls', 'authService', 'commonUtil'];

    function trace($q, $http, $rootScope, $log, cacheService, appSettings, serviceUrls, authService, commonUtil) {

        var featureEnabled = false;
        var traceUploadTimer = undefined;
        var traceStart = "";
        var sessionId = "";

        var trace = {
            initService: initService,
            clear: clear,
            enter: function (fnName, message) {
                cacheTrace('enter', fnName, message);
            },
            exit: function (fnName, message) {
                cacheTrace('exit', fnName, message);
            },
            info: function (fnName, message) {
                cacheTrace('info', fnName, message);
            },
            error: function (fnName, message) {
                cacheTrace('error', fnName, message);
            },
            warning: function (fnName, message) {
                cacheTrace('warning', fnName, message);
            }
        }

        return trace;

        function clear() {
            var deferred = $q.defer();
            if ($rootScope.enableTracing) {
                if (traceUploadTimer) {
                    clearInterval(traceUploadTimer);
                }

                var allTraceKeys = [];
                uploadTraces() //upload the current trace before deleting all
                    .then(() => {
                        return cacheService.getByPrefix('trace');
                    })
                    .then(tracekeys => {
                        for (var tracekey in tracekeys) {
                            if (Object.prototype.hasOwnProperty.call(tracekeys, tracekey)) {
                                allTraceKeys.push('trace' + tracekey);
                            }
                        }
                        return $q.all(_.map(allTraceKeys, traceKey => removeTrace(traceKey)));
                    })
                    .then(() => {
                        return cacheService.has('_traceDescriptors');
                    })
                    .then((exists) => {
                        if (exists) {
                            return cacheService.remove('_traceDescriptors');
                        } else {
                            deferred.resolve();
                        }
                    })
                    .then(() => {
                        deferred.resolve();
                    });
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        }

        function removeTrace(traceKey) {
            var deferred = $q.defer();

            cacheService.has(traceKey)
                .then(exists => {
                    if (exists) {
                        cacheService.remove(traceKey)
                            .then(() => {
                                deferred.resolve();
                            });
                    } else {
                        deferred.resolve();
                    }
                });

            return deferred.promise;
        }

        function initService() {
            sessionId = commonUtil.generateGuid();

            var deferred = $q.defer();
            cacheService.set('_traceDescriptors', [])
                .then(() => {
                    return createNewTrace(moment.utc().format("YYYYMMDDTHHmmss"));
                })
                .then((traceName) => {
                    return cacheService.appendTo('_traceDescriptors',
                        {
                            traceId: traceName,
                            state: 'created'
                        });
                })
                .then(() => {
                    if (traceUploadTimer) {
                        clearInterval(traceUploadTimer);
                        traceUploadTimer = setInterval(uploadTraces, appSettings.traceIntervalLength);
                    } else {
                        traceUploadTimer = setInterval(uploadTraces, appSettings.traceIntervalLength);
                    }
                });
            return deferred.promise;
        }

        function createNewTrace(startDate) {
            var deferred = $q.defer();
            var traceName = "";
            authService.getAuthData()
                .then(function (authData) {
                    traceStart = '_' + sessionId + "_" + startDate;
                    traceName = authData.username + '_trace' + traceStart;
                    return cacheService.set('trace' + traceStart, []);
                }, function () {
                    deferred.reject();
                })
                .then(function () {
                    deferred.resolve(traceName);
                });

            return deferred.promise;
        }

        function uploadTraces() {
            var traceDescriptors = [];
            var deferred = $q.defer();
            cacheService.get('_traceDescriptors')
                .then((descriptors) => {
                    if (descriptors) {
                        traceDescriptors = descriptors;
                        var usedTraces = _.filter(traceDescriptors, { 'state': 'created' });
                        for (var i = 0; i < usedTraces.length; i++) {
                            usedTraces[i].state = 'finished';
                        }
                        return $q.all(_.map(usedTraces, tD => saveLocalTraceState({
                            traceId: tD.traceId,
                            state: 'finished'
                        })));
                    }
                })
                .then(() => {
                    return createNewTrace(moment.utc().format("YYYYMMDDTHHmmss"));
                })
                .then((traceName) => {
                    return cacheService.appendTo('_traceDescriptors', {
                        traceId: traceName,
                        state: 'created'
                    });
                })
                .then(() => {
                    if (traceDescriptors && $rootScope.isOnline) {
                        var notUploadedTraceDescriptors = _.filter(traceDescriptors, { 'state': 'finished' });
                        return $q.all(_.map(notUploadedTraceDescriptors, tD => uploadTrace(tD.traceId)));
                    } else {
                        deferred.reject();
                    }
                }).then(() => {
                    deferred.resolve();
                });

            return deferred.promise;
        }

        function uploadTrace(traceId) {
            var traceKey = traceId.split('_').slice(1).join('_');
            cacheService.get(traceKey)
                .then(traceContent => {
                    return $http.post(serviceUrls.trace, {
                        name: traceId,
                        content: JSON.stringify(traceContent)
                    });
                })
                .then(resp => {
                    if (resp && resp.data && resp.status === 200
                    ) {
                        var traceId = resp.data.message;
                        saveLocalTraceState({
                            traceId: traceId,
                            state: 'uploaded'
                        });
                        cacheService.remove(traceKey);
                    }
                });
        }

        function saveLocalTraceState(localTrace) {
            var deferred = $q.defer();

            cacheService.replaceIn('_traceDescriptors', null, localTrace, 'traceId', localTrace.traceId)
                .then(function () {
                    deferred.resolve();
                })
                .catch(function (error) {
                    $log.warn('failed to update trace with traceId' + localTrace.traceId + ' ' + error);
                    deferred.resolve();
                });

            return deferred.promise;
        }

        function cacheTrace(level, fnName, message) {
            if (featureEnabled && $rootScope.enableTracing) {
                var traceItem = {
                    trace: level + "," + fnName + "," + (message ? message : "") + ", " + moment.utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') + ", "
                };

                cacheService.appendTo('trace' + traceStart, traceItem);
            }
        }
    }
})();

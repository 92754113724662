(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('SettingsController', settingsController);

    settingsController.$inject = ['$q', '$scope', '$rootScope', '$timeout', 'cacheService', 'settingsService', 'expirationService', 'authService', 'appSettings'];

    function settingsController($q, $scope, $rootScope, $timeout, cacheService, settingsService, expirationService, authService, appSettings) {
        $scope.clearCacheEnabled = appSettings.clearCacheEnabled;
        $scope.updating = false;
        $scope.successTextShow = false;

        $scope.clearCache = clearCache;
        $scope.updateSettings = updateSettings;

        function clearCache() {
            if(confirm("Er du sikkert på at du ønsker å slette lokalt lagrede data?")) {
                $rootScope.$broadcast('showBusyIndicator');

                cacheService.getAll()
                    .then(function (keyValuePairs) {
                        var filteredKeys = _.filter(_.keys(keyValuePairs), function (key) {
                            return !_.startsWith(key, '_orders') &&
                                !_.startsWith(key, '_routeStops') &&
                                !_.startsWith(key, '_deviation') &&
                                !_.startsWith(key, '_updateQueue');
                        });

                        var requests = _.map(filteredKeys, function (key) {
                            return cacheService.remove(key);
                        });

                        return $q.all(requests);
                    })
                    .then(function () {
                        return expirationService.deleteExpiredOrders();
                    })
                    .then(function () {
                        return authService.logout();
                    })
                    .finally(function() {
                        $rootScope.$broadcast('hideBusyIndicator');
                    });
            }
        }

        function updateSettings() {
            $scope.saving = true;
            $scope.successTextShow = false;

            $rootScope.$broadcast('showBusyIndicator');

            settingsService.getSettings(true).then(function (settings) {
                $rootScope.userSettings = settings;
            });

            $timeout(function () {
                $scope.saving = false;
                $scope.successTextShow = true;

                $rootScope.$broadcast('hideBusyIndicator');

                $timeout(function () {
                    $scope.successTextShow = false;
                }, 2000);
            }, 1500);
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('passwordInput', passwordInput);

    passwordInput.$inject = [];


    function passwordInput() {
        return {
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl) {
                function fromUser(text, scope) {
                    if (!scope.realPassword) {
                        scope.realPassword = "";
                    }

                    if (text) {
                        if (text.length > scope.realPassword.length) {
                            scope.realPassword = scope.realPassword.concat(text[text.length-1]);
                        }else {
                            if (text.length < scope.realPassword.length) {
                                scope.realPassword = scope.realPassword.substring(0, scope.realPassword.length - 1);
                            }
                        }

                        var stars = _.map((new Array(text.length)), () => '*').join("");
                        ngModelCtrl.$setViewValue(stars);
                        ngModelCtrl.$render();
                        return stars;
                    }
                    return undefined;
                }
                ngModelCtrl.$parsers.push((text)=>fromUser(text,scope));
            }
        };
    }

})();

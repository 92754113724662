(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('messagesService', messagesService);

    messagesService.$inject = [
        '$rootScope',
        '$q',
        '$http',
        '$interval',
        'appSettings',
        'messageState',
        'cacheService',
        'updateQueue',
        'commonUtil'
    ];

    function messagesService(
        $rootScope,
        $q,
        $http,
        $interval,
        appSettings,
        messageState,
        cacheService,
        updateQueue,
        commonUtil
    ) {
        const cachePrefix = '_messages_';
        let timer = null,
            lastPollFinishedAt = null,
            isPollRunning = false;

        let messagesService = {
            getRouteStopMessage,
            startMessagePolling,
            stopMessagePolling,
            updateMessageStatus,
            updateMessageStatusRequest,
            sendMessage,
            sendMessageRequest,
        };

        return messagesService;

        //Public interface
        function getRouteStopMessage(agreementId) {
            var deferred = $q.defer();

            cacheService.get(cachePrefix).then(function (cachedMessages) {
                cachedMessages = cachedMessages || [];
                var routeStopMessage = _.find(cachedMessages, { agreementId: agreementId });
                deferred.resolve(routeStopMessage);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;

        }

        function startMessagePolling() {
            if (timer == null) {
                isPollRunning = false;

                timer = $interval(function () {
                    let shouldRunPoll = true;

                    //Avoid starting poll right after response returned if server response time is high
                    if (lastPollFinishedAt) {
                        if (moment().diff(lastPollFinishedAt, 'millisecond') < appSettings.directMessagePollingIntervalMs / 3) {
                            shouldRunPoll = false;
                        }
                    }

                    if (shouldRunPoll && !isPollRunning) {
                        isPollRunning = true;

                        pollMessages()
                            .then(() => lastPollFinishedAt = new Date())
                            .finally(() => isPollRunning = false);
                    }
                }, appSettings.directMessagePollingIntervalMs);
            }
        }

        function stopMessagePolling() {
            $interval.cancel(timer);
            timer = null;
            isPollRunning = false;
        }

        function updateMessageStatus(messageId) {
            var deferred = $q.defer();

            cacheService.get(cachePrefix)
                .then(function (messages) {
                    var message = _.find(messages, { 'id': messageId });
                    message.messageState = messageState.readProfile;

                    return cacheService.set(cachePrefix, messages);
                })
                .then(function () {
                    var updateAction = {
                        id: commonUtil.generateGuid(),
                        parameters: [messageId],
                        type: 'updateMessageStatus'
                    };

                    return updateQueue.addUpdateAction(updateAction);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function updateMessageStatusRequest(messageId) {
            var deferred = $q.defer();

            $http.put('api/message/' + messageId + '/status').then(function() {
                    deferred.resolve();
            },
            function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        function sendMessage(message) {
            var updateAction = {
                id: commonUtil.generateGuid(),
                parameters: [message],
                type: 'sendMessage',
                retryCount: 10,
            };

            return updateQueue.addUpdateAction(updateAction);
        }

        function sendMessageRequest(message) {
            return $http.post('api/message/sendmessage', message);
        }

        //Private functions
        function pollMessages() {
            return $http.get('api/message/directmessages').then(function (response) {
                if (response && response.data && response.data.length > 0) {
                    var messages = response.data;

                    cacheService.get(cachePrefix).then(function (cachedMessages) {
                        cachedMessages = cachedMessages || [];

                        _.forEach(messages, function (m) {
                            if (_.findIndex(cachedMessages, m) === -1) {
                                cachedMessages.push(m);
                            }
                        });

                        $rootScope.directMessages = _.orderBy(cachedMessages, ['messageState', 'date'], ['asc', 'desc']);

                        cacheService.set(cachePrefix, $rootScope.directMessages);

                        $rootScope.messageBadgeCount = $rootScope.directMessages.filter(m => m.messageState === messageState.new).length;
                        $rootScope.showMessageBadge = $rootScope.messageBadgeCount > 0;
                    });
                }
            });
        }
    }
})();

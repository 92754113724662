(function () {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("uploadCacheModalController", uploadCacheModalController);

    uploadCacheModalController.$inject = [
        "$scope",
        "$windowInstance"
    ];

    function uploadCacheModalController(
        $scope,
        $windowInstance
    ) {

        $scope.uploadFile = uploadFile;

        function uploadFile(e) {
            e.preventDefault();

            const intentionConfirm = confirm('Are you sure?');
            
            if (!intentionConfirm) return;

            const fileInputEl = document.getElementById('indexedDbFileUpload');
            const file = fileInputEl.files[0];

            if (file){
                const reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = function (evt) {
                    loadToIndexedDb(evt.target.result);
                    $windowInstance.close();
                }
                reader.onerror = function (evt) {
                    alert('Error while reading the file!');
                }
                reader.onloadend = function (evt) {
                    fileInputEl.value = null;
                }
            }

        }

        function loadToIndexedDb(text){
            
            try {

                if (!text || typeof text !== 'string') throw 'Invalid input data';

                const data = JSON.parse(text.replaceAll('\n',' '));
                const request = indexedDB.open('DTA');

                request.onsuccess = function (e) {

                    const db = e.target.result;
                    const trans = db.transaction('keyvaluepairs', 'readwrite');
                    const objStore = trans.objectStore('keyvaluepairs');
                    const authDataRequest = objStore.get('authData');
                    let authToken = '';
                    
                    authDataRequest.onsuccess = function (event) {
                        authToken = event.target.result.token;
                    }
                    authDataRequest.onerror = function (event) {
                        throw 'Could not open current IndexedDB!';
                    }

                    const insertRequest = objStore.clear();

                    insertRequest.onsuccess = function (evt) {
                        data.forEach(item => {
                            if (item.key === 'authData') item.value.token = authToken;
                            objStore.put(item.value, item.key);
                        })
                        alert('Succesfully imported!');
                    }

                    insertRequest.onerror = function (e) {
                        throw 'Could not rewrite the IndexedDB! Please log out and log in again!'
                    }

                }
                request.onerror = function (e) {
                    throw 'Could not open current IndexedDB!';
                }

            } catch (error) {

                alert(error);

            }
        }

    }
})();

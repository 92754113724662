(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('SendReportController', sendReportController);

    sendReportController.$inject = ['$rootScope', '$scope', '$windowInstance', 'errorReportService'];

    function sendReportController($rootScope, $scope, $windowInstance, errorReportService) {
        $scope.send = send;
        $scope.message = '';

        function send() {
            if ($scope.message.length > 0) {
                $rootScope.$broadcast('showBusyIndicator');
                errorReportService.sendErrorReport($scope.message)
                    .then(function () {
                        alert('Rapport sendt!');
                        $windowInstance.close();
                    })
                    .catch(function () {
                        alert('Error occured - report not sent!');
                    })
                    .finally(function () {
                        $rootScope.$broadcast('hideBusyIndicator');
                    });
            } else {
                alert('Please provide a short message!');
            }
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('busyIndicator', busyIndicator);

    busyIndicator.$inject = [];

    function busyIndicator() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/busy-indicator.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var spinner = $('.loader');
            scope.$on('showBusyIndicator', function (event, params) {
                scope.message = params ? params.message : null;
                scope.buttonText = params ? params.buttonText : null;
                scope.buttonType = params ? params.buttonType : null;

                scope.position = params ? params.position : null;

                if (scope.position) {
                    spinner.css('position', 'absolute');
                    spinner.css('left', scope.position.left);
                    spinner.css('top', scope.position.top);
                } else {
                    spinner.css('position', 'fixed');
                    spinner.css('left', '50%');
                    spinner.css('top', '50%');
                }
                spinner.css('display', 'flex');
            });

            scope.$on('hideBusyIndicator', function () {
                spinner.hide();
            });

            scope.buttonClicked = function() {
                scope.$broadcast(scope.buttonType);
            };
        }
    }
})();

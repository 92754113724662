(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('multipleChoiceModalService', multipleChoiceModalService);

    multipleChoiceModalService.$inject = ['$kWindow'];

    function multipleChoiceModalService($kWindow) {
        var service = {
            openMultipleChoiceModal,
        };

        return service;

        function openMultipleChoiceModal({ choices, title }) {
            const height = 16 + 40 * choices;

            const window = $kWindow.open({
                options: {
                    modal: true,
                    title: title || 'Velg et alternativ',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height,
                    width: 360
                },
                templateUrl: 'app/shared/multipleChoiceModal/multiple-choice-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'MultipleChoiceModalController',
                resolve: {
                    choices: () => choices,
                }
            });

            return window.result;
        }
    }
})();

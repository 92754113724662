(function() {
    angular.module('PWAPoCApp').controller('WeightDataButtonModalController', weightDataButtonModalController);

    weightDataButtonModalController.$inject = ['$timeout', '$scope', '$windowInstance', 'routeStop', 'dataButton'];

    function weightDataButtonModalController($timeout, $scope, $windowInstance, routeStop, dataButton) {
        $scope.numbers = '';
        
        $scope.addComma = addComma;
        $scope.close = close;
        $scope.save = save;
        $scope.togglePopupVisible = togglePopupVisible;
        $scope.units = _.map(routeStop.units,
            function(u) {
                return {
                    agreementLineIds: u.agreementLines.join(','),
                    unitName: u.address + ', ' + u.name,
                    numbers: u.numbers
                }
            });
        $scope.selectedUnit = {};

        var sumWeight = '';
        var previousSelectedUnit = null;
        initController();

        $scope.unitOptions = {
            dataSource: {
                transport: {
                    read: readUnits
                }
            },
            dataTextField: 'unitName',
            dataValueField: 'agreementLineIds',
            optionLabel: ' ',
            change: selectionChanged
        };

        $scope.loadWeightValue = function (unit) {
            if (!unit || !unit.agreementLineIds || unit.agreementLineIds.length === 0) {
                $scope.numbers = sumWeight;
            } else {
                $scope.numbers = unit.numbers ? unit.numbers : '';
            }
        }

        $scope.storeWeightValue = function (unit) {
            if (!unit || !unit.agreementLineIds || unit.agreementLineIds.length === 0) {
                sumWeight = $scope.numbers;
            } else {
                var val = correctFloatFormat($scope.numbers);
                unit.numbers = val;
            }
        }


        function correctFloatFormat(data) {
            var dataValue = data;
            if (dataValue.indexOf(',') === 0) {
                dataValue = '0' + dataValue;
            }

            if (dataValue.indexOf(',') === dataValue.length - 1) {
                dataValue = dataValue.substring(0, dataValue.length - 1);
            }
            return dataValue;
        }

        function selectionChanged(e) {
            var index = e.sender.selectedIndex;
            var unit = index > 0 ? $scope.units[index - 1] : {};
            $scope.storeWeightValue(previousSelectedUnit);
            previousSelectedUnit = unit;
            $scope.loadWeightValue(unit);
        }

        function readUnits(e) {
            e.success($scope.units);
        }
        function togglePopupVisible(unit)
        {
            $scope.selectedUnit = unit;
            $scope.popupVisible = !$scope.popupVisible;
        }

        function initController() {
            if (dataButton.value) {
                $scope.numbers = dataButton.value;
            }
        }

        function addComma() {
            if ($scope.numbers.indexOf(',') === -1) {
                $scope.numbers += ',';
            }
        }

        function close() {
            $windowInstance.close({
                action: 'cancel',
                value: null
            });
        }

        function save() {
            
            var weightValues = [];
            var unitsWithNumbers = _.filter($scope.units, u => !!u.numbers);
            var activeUnit = _.find($scope.units, u => u.agreementLineIds === $scope.selectedUnit.agreementLineIds);
            var anyUnitsEdited = (unitsWithNumbers && unitsWithNumbers.length > 0) || activeUnit;
            if (anyUnitsEdited) {
                if (activeUnit) {
                    activeUnit.numbers = $scope.numbers;
                }
                _.forEach($scope.units,
                    u => {
                        weightValues.push({
                            agreementLineIds: u.agreementLineIds,
                            value: u.numbers
                        });
                    });
            }else {
                weightValues.push({
                    agreementLineIds: "",
                    value: $scope.numbers
                });
            }
           

            $windowInstance.close({
                action: 'save',
                value: { anyUnitsEdited, weightValues },
                routeStop: routeStop
            });
        }
    }
})();

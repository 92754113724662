(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('ContainerStatisticsModalController', containerStatisticsModalController);

    containerStatisticsModalController.$inject = ['$timeout', '$rootScope', '$scope', '$windowInstance', 'containersService', 'containerId'];

    function containerStatisticsModalController($timeout, $rootScope, $scope, $windowInstance, containersService, containerId) {
        var emptyLevel = 2431;

        $scope.hasData = false;
        $scope.loaded = false;
        $scope.ok = ok;

        $scope.options = {
            chartType: { type: 'line' },
            dataSource: $scope.chartData,
            series: [],
            valueAxes: []
        };

        $timeout(250).then(initController);

        function initController() {
            $rootScope.$broadcast('showBusyIndicator');

            containersService.getContainerStatistics(containerId).then(function (statistics) {
                if (statistics) {
                    $scope.statistics = statistics;

                    addChartData('Signalstyrke', statistics, 'rssi', 'timestamp', 'red', 'line', true, getMinValue(statistics, 'rssi'), getMaxValue(statistics, 'rssi'));
                    addChartData('Batteri', statistics, 'volt', 'timestamp', 'green', 'line', true, getMinValue(statistics, 'volt'), getMaxValue(statistics, 'volt'));
                    addChartData('Temperatur', statistics, 'temperature', 'timestamp', 'orange', 'line', true, getMinValue(statistics, 'temperatur'), getMaxValue(statistics, 'temperatur'));
                    addChartData('Fyllingsgrad', statistics, 'fill', 'timestamp', 'blue', 'line', true, 0, 110, function (item) { return Math.round(item)});
                    addChartData('Aksesseringer', statistics, 'accessCount', 'timestamp', 'gray', 'bar', true, getMinValue(statistics, 'accesscount'), getMaxValue(statistics, 'accessCount'));
                    addChartData('Verwendungen', statistics, 'verwendungen', 'timestamp', 'purple', 'line', true, getMinValue(statistics, 'verwendungen'), getMaxValue(statistics, 'verwendungen'));

                    setCategoryAxis(statistics);
                }

                $scope.hasData = $scope.statistics && $scope.statistics.length;
                $scope.loaded = true;

                $rootScope.$broadcast('hideBusyIndicator');
            }, function () {
                $scope.loaded = true;
                $rootScope.$broadcast('hideBusyIndicator');
            });
        }

        function ok() {
            $windowInstance.close();
        }

        function addChartData(name, items, column, category, color, chartType, tooltipVisible, min, max, special) {
            var data = [];
            var times = [];
            _.each(items, function (item) {
                if (special) {
                    data.push(special(item[column]));
                }
                else {
                    data.push(item[column]);
                }
                times.push(item[category]);
            });
            $scope.options.series.push({
                name: name,
                data: data,
                axis: name,
                color: color,
                type: chartType,
                tooltip: {
                    visible: tooltipVisible,
                    template: "Dag: #= category #<br />#= series.name #: #= value #"
                }
            });
            $scope.options.valueAxes.push({
                name: name,
                color: color,
                min: min,
                max: max
            });
        }

        function getMinValue(statistics, column) {
            return _.min(statistics[column]);
        }

        function getMaxValue(statistics, column) {
            return _.max(statistics[column]);
        }

        function setCategoryAxis(items) {
            var times = [];
            _.each(items, function (item) {
                times.push(moment(item.timestamp).format('DD.MM.YYYY hh:mm:ss') == '01.01.1900' ? ' ' : moment(item.timestamp).format('DD'));
            });

            var c = _.size(times);
            $scope.options.categoryAxis = {
                categories: times,
                majorGridLines: {
                    visible: false
                },
                axisCrossingValues: [0, 0, 0, c, c, c]
            };
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('nearbyRouteStopsService', nearbyRouteStopsService);

    nearbyRouteStopsService.$inject = ['$q', '$log', 'cacheService'];

    function nearbyRouteStopsService($q, $log, cacheService) {
        var timer = null;
        var nearbyDistanceKey = '_nearbyRouteStopsDistance';

        var nearbyRouteStopsService = {
            getNearbyDistance: getNearbyDistance,
            saveNearbyDistance: saveNearbyDistance
        };

        return nearbyRouteStopsService;

        function getNearbyDistance() {
            var deferred = $q.defer();

            cacheService.get(nearbyDistanceKey).then(function (dist) {
                deferred.resolve(dist);
            });

            return deferred.promise;
        }

        function saveNearbyDistance(value) {
            var deferred = $q.defer();

            cacheService.set(nearbyDistanceKey, value).then(function () {
                deferred.resolve();
            },
            function (err) {
                $log.error('failed to set key' + err);
                deferred.resolve();
            });

            return deferred.promise;
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('confirmBackController', confirmBackController);

    confirmBackController.$inject = ['$scope', '$windowInstance'];

    function confirmBackController($scope, $windowInstance) {
 
        $scope.yes = function() {
            $windowInstance.close(true);
        }

        $scope.no = function() {
            $windowInstance.close(false);
        }
    }
})();

(function () {
    angular.module('PWAPoCApp').factory('offlineInterceptor', offlineInterceptor);

    offlineInterceptor.$inject = ['$q', '$rootScope'];

    function offlineInterceptor($q, $rootScope) {
        var offline;

        return {
            response: response,
            responseError: responseError
        };

        function response(response) {
            if (offline && (response && (response.status === 200 || response.status === 304))) {
                offline = false;
                $rootScope.connectionChanged();
            }

            return response || $q.when(response);
        }

        function responseError(rejection) {
            if (!offline && (rejection && (rejection.status === 0 || rejection.status === -1))) {
                offline = true;
                $rootScope.connectionChanged();
            }

            return $q.reject(rejection);
        }
    }
})();

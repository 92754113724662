(function () {
    angular.module('PWAPoCApp').factory('$exceptionHandler', exceptionHandler);

    exceptionHandler.$inject = ['$injector', '$log', 'appSettings'];

    function exceptionHandler($injector, $log, appSettings) {
        var timestampCacheKey = '_quotaExceededErrorReportTimestamp';

        var handler = function (exception, cause) {
            if (isQuotaExceededException(exception)) {
                var cacheService = $injector.get('cacheService');
                cacheService.get(timestampCacheKey).then(function(timestamp) {
                    if (!timestamp || moment.duration(moment().diff(moment(timestamp))).asMilliseconds() > appSettings.quotaExceededErrorReportMinIntervalMs) {
                        var errorReportService = $injector.get('errorReportService');
                        errorReportService.sendErrorReport(exception.message, true).then(function() {
                            cacheService.set(timestampCacheKey, moment().format());
                        });
                    }
                });
            } else {
                $log.error(exception);
            }
        };

        return handler;
    }

    function isQuotaExceededException(exception) {
        var result = false;

        if (exception && exception.message && exception.message.length) {
            var lowerCase = _.lowerCase(exception.message);
            result = _.includes(lowerCase, 'quota') && _.includes(lowerCase, 'exceeded');
        }

        return result;
    }
})();

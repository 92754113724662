(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('kendoWindowClose', kendoWindowClose);

    kendoWindowClose.$inject = [];

    function kendoWindowClose() {
        var directive = {
            link: link
        };

        return directive;

        function link(scope, elem, attrs) {
            elem.on('click touchstart', '.k-overlay', function () {
                var kendoWindow = $('.k-window-content.k-content', $(this).next('div.k-widget.k-window'));
                if (kendoWindow == null || kendoWindow.length == 0) {
                    return;
                }
                if (!scope.overlayDisabled) {
                    kendoWindow.data('kendoWindow').close();
                }
            });
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('containersService', containersService);

    containersService.$inject = ['$q', '$http'];

    function containersService($q, $http) {
        var service = {
            getContainerStatistics: getContainerStatistics,
            getLogsByExtDeviceIdAndSystem: getLogsByExtDeviceIdAndSystem,
            getLogsByExtDeviceIdAndSystemBulk: getLogsByExtDeviceIdAndSystemBulk
        };

        return service;

        function getContainerStatistics(containerId) {
            var deferred = $q.defer();

            $http.get('api/containers/' + containerId + '/statistics').then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getLogsByExtDeviceIdAndSystem(containerId) {
            var deferred = $q.defer();

            $http.get('api/containers/' + containerId + '/deviceLogs').then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getLogsByExtDeviceIdAndSystemBulk(containerIds) {
            var deferred = $q.defer();

            $http.post('api/containers/deviceLogs/bulk', containerIds)
                .then(function (response) {
                    if (response) {
                        deferred.resolve(response);
                    } else {
                        deferred.reject();
                    }
                }, function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();

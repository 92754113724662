(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('healthInfoService', healthInfoService);

    healthInfoService.$inject = ['$q', '$http', '$rootScope', 'cacheService', 'locationService', 'appVersion', 'serviceUrls'];

    function healthInfoService($q, $http, $rootScope, cacheService, locationService, appVersion, serviceUrls) {
        var service = {
            update: update
        };

        return service;

        function update() {
            var deferred = $q.defer();
            var requests = [getLocalStorageSizes(), locationService.getCurrentPosition()];

            $q.all(requests)
                .then(function (data) {
                    var localStorageSizes = data[0],
                        position = data[1];

                    if (position && position.coords && position.coords.latitude) {

                        var healthInfo = {
                            accuracy: position.coords.accuracy,
                            latitude: position.coords.latitude,
                            localStorageSizeMB: localStorageSizes.total,
                            localStorageDetailed: localStorageSizes.detailed,
                            longitude: position.coords.longitude,
                            orderId: $rootScope.activeOrderId,
                            speed: position.coords.speed,
                            version: appVersion
                        };

                        return $http.post(serviceUrls.healthInfo, healthInfo);
                    }
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function() {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getLocalStorageSizes() {
            var deferred = $q.defer();

            var sizes = {};
            var total = 0;

            cacheService.iterate(function (value, key) {
                    var size = JSON.stringify(value).length * 2 / 1024 / 1024;
                    total += size;
                    sizes[key] = size;
                    return;
                })
                .then(function () {
                    var result = { total: total, detailed: JSON.stringify(sizes) };
                    deferred.resolve(result);
                });

            return deferred.promise;
        }
    }
})();

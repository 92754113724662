(function () {
    angular.module('PWAPoCApp').factory('logInterceptorService', logInterceptorService);

    logInterceptorService.$inject = ['$q', '$log'];

    function logInterceptorService($q, $log) {
        return {
            request: request,
            response: response
        };
        
        function request(config) {
            var log = {
                headers: config.headers,
                method: config.method,
                url: config.url
            };

            $log.debug(log);

            return config || $q.when(config);
        }

        function response(response) {
            var log = {
                data: response.data,
                headers: response.config.headers,
                method: response.config.method,
                status: response.status,
                statusText: response.statusText,
                url: response.config.url
            };

            $log.debug(log);

            return response || $q.when(response);
        }
    }

    
})();

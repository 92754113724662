(function () {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("diagnosticsModalController", diagnosticsModalController);

    diagnosticsModalController.$inject = [
        "$scope",
        "$timeout",
        "$kWindow",
        "authService",
        "cacheService",
    ];

    function diagnosticsModalController(
        $scope,
        $timeout,
        $kWindow,
        authService,
        cacheService
    ) {
        $scope.username = "";
        $scope.indexedDb = "";
        $scope.shortValueCacheItems = [];
        $scope.selectedRecord = "";
        $scope.copyTooltipVisible = "";
        $scope.copyToClipboard = copyToClipboard;
        $scope.downloadAsTxt = downloadAsTxt;
        $scope.selectKey = selectKey;
        $scope.openUploadModal = openUploadModal;

        let cacheItems;
        let selectedKey;
        let isTableActive = false;

        $timeout(250).then(initModal);

        function initModal() {
            authService.getAuthData().then(function (authData) {
                $scope.username = authData.username;
            });

            cacheService.getAllKeysWithoutUserPrefix().then(function (items) {
                cacheItems = items;
                $scope.shortValueCacheItems = items.map((item) => {
                    let newItem = {};
                    newItem.key = item.key;
                    newItem.value =
                        JSON.stringify(item.value).substring(0, 75) +
                        (JSON.stringify(item.value).length > 75 ? "..." : "");
                    return newItem;
                });
            });
        }

        document.addEventListener("click", function (e) {
            if (e.target.closest("#indexedDbRecords") !== null) {
                isTableActive = true;
            } else {
                isTableActive = false;
            }
        });

        document.addEventListener("keydown", moveSelection);

        function moveSelection(e) {
            if (!isTableActive) return;
            if (!selectedKey) return;
            const tableRowEls = document.querySelectorAll(
                "#indexedDbRecords tbody tr"
            );
            if (!tableRowEls) return;
            let positionActive = -1;
            let position = -1;
            tableRowEls.forEach((row) => {
                position++;
                if (row.classList.contains("active")) {
                    positionActive = position;
                }
            });
            switch (e.code) {
                case "ArrowUp":
                    const beforeEl = tableRowEls[positionActive - 1];
                    if (positionActive - 1 < 0) break;
                    beforeEl.click();
                    break;
                case "ArrowDown":
                    const afterEl = tableRowEls[positionActive + 1];
                    if (positionActive + 1 >= tableRowEls.length) break;
                    afterEl.click();
                    break;
                default:
                    break;
            }
        }

        function selectKey(event, key) {
            const el = event.currentTarget;
            keySelectionVisuals(el);
            keySelectionBackend(key);
        }

        function keySelectionVisuals(element) {
            const table = element.closest("tbody");
            if (!element || !table) return;
            const rows = table.querySelectorAll("tr");
            if (rows.lenght < 1) return;

            rows.forEach((row) => {
                row.classList.remove("active");
            });

            element.classList.add("active");
        }

        function keySelectionBackend(key) {
            const cacheItem = cacheItems.filter((item) => item.key === key);
            selectedKey = cacheItem.shift();
            $scope.selectedRecord = selectedKey;
        }

        function copyToClipboard($event) {
            const name = contentSelecterByElement($event.currentTarget);
            var copyElement = document.createElement("textarea");
            copyElement.style.position = "fixed";
            copyElement.style.opacity = "0";
            copyElement.textContent = decodeURI(name);
            var body = document.getElementsByTagName("body")[0];
            body.appendChild(copyElement);
            copyElement.select();
            document.execCommand("copy");
            body.removeChild(copyElement);
            tooltipToggler($event.currentTarget);
        }

        function downloadAsTxt($event) {
            const content = contentSelecterByElement($event.currentTarget);
            const name = contentNameSelecterByElement($event.currentTarget);
            const date = new Date();
            const timeStamp = kendo.toString(new Date(), "yyyyMMdd-hhmmss");
            saveFile(`${name}-${timeStamp}.txt`, content);
            tooltipToggler($event.currentTarget);
        }

        function contentSelecterByElement(element) {
            switch (element.getAttribute("for")) {
                case "allCache":
                    return JSON.stringify(cacheItems);
                case "selectedKey":
                    return JSON.stringify(selectedKey);
                default:
                    return "";
            }
        }

        function contentNameSelecterByElement(element) {
            switch (element.getAttribute("for")) {
                case "allCache":
                    return "IndexedDB";
                case "selectedKey":
                    return selectedKey.key;
                default:
                    return "";
            }
        }

        function tooltipToggler(buttonEl) {
            const tooltipEl = buttonEl.parentElement.querySelector(
                "span.successfullOperation"
            );

            if (!tooltipEl) return;

            tooltipEl.classList.add("visible");
            $timeout(2500).then(() => tooltipEl.classList.remove("visible"));
        }

        function saveFile(filename, data) {
            const blob = new Blob([data], { type: "text/csv" });
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
            } else {
                const elem = window.document.createElement("a");
                elem.href = window.URL.createObjectURL(blob);
                elem.download = filename;
                document.body.appendChild(elem);
                elem.click();
                document.body.removeChild(elem);
            }
        }

        function openUploadModal(){
            const uploadModal = $kWindow.open({
                options: {
                    modal: true,
                    title: 'Upload IndexedDB',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 320,
                    width: 450
                },
                templateUrl: 'app/diagnostics/uploadModal/upload-cache-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'uploadCacheModalController'
            });

            uploadModal.result
            .then(function(){
                $timeout(250).then(initModal);
            })
        }
    }
})();

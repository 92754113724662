(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('areaService', areaService);

    areaService.$inject = [
        '$q',
        '$http',
        'cacheService'
    ];

    function areaService(
        $q,
        $http,
        cacheService
    ) {
        const cachePrefix = '_areas_';
        const areasCacheKey = cachePrefix + 'areas';
        const selectedAreasCacheKey = cachePrefix + 'selected';

        let service = {
            getAreas,
            getSelectedAreas,
            setSelectedAreas,
            isRouteStopInArea
        };

        return service;

        function getAreas(refreshCache) {
            let deferred = $q.defer();

            if (!refreshCache) {
                cacheService.has(areasCacheKey)
                    .then(exists => {
                        if (exists) {
                            return cacheService.get(areasCacheKey);
                        } else {
                            return getAreasServer();
                        }
                    })
                    .then(areas => deferred.resolve(areas))
                    .catch(deferred.reject);
            } else {
                getAreasServer()
                    .then(areas => deferred.resolve(areas))
                    .catch(deferred.reject);
            }

            return deferred.promise;
        }

        function getSelectedAreas() {
            return cacheService.get(selectedAreasCacheKey);
        }

        function setSelectedAreas(selectedAreas) {
            return cacheService.set(selectedAreasCacheKey, selectedAreas);
        }

        function isRouteStopInArea(area, routeStop) {
            if (!area || !routeStop || !routeStop.latitude && !routeStop.longitude) {
                return false;
            }

            let inside = false;
            let x = routeStop.longitude, y = routeStop.latitude;
            for (let i = 0, j = area.points.length - 1; i < area.points.length; j = i++) {
                let xi = area.points[i].longitude, yi = area.points[i].latitude;
                let xj = area.points[j].longitude, yj = area.points[j].latitude;

                let intersect = ((yi > y) !== (yj > y))
                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);

                if (intersect) inside = !inside;
            }

            return inside;
        }

        //Private functions
        function getAreasServer() {
            let deferred = $q.defer(),
                areas = [];

            $http.get('api/area')
                .then(response => {
                    areas = response.data.filter(a => a.isActive);
                    return cacheService.set(areasCacheKey, areas);
                })
                .then(() => deferred.resolve(areas))
                .catch(deferred.reject);

            return deferred.promise;
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('inrouteMenu', inrouteMenu);

    inrouteMenu.$inject = [
        '$rootScope',
        '$state',
        'cacheService',
        'appSettings'
    ];

    function inrouteMenu(
        $rootScope,
        $state,
        cacheService,
        appSettings
    ) {
        var directive = {
            restrict: 'E',
            scope: {
                cancel: '&ngClickCancel',
                close: '&ngClickClose',
                toggleCompleted: '&ngClickCompleted',
                settings: '&ngClickSettings',
                support: '&ngClickSupport',
                nearby: '&ngClickNearby'
            },
            transclude: true,
            templateUrl: 'app/shared/directives/inroute-menu.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.isUndergroundOrServiceUser = getIsUndegroundOrServiceUser();

            var mobileDetect = new MobileDetect(window.navigator.userAgent);
            scope.fadeModal = !mobileDetect.is('AndroidOS');

            var sliderConfig = appSettings.routeStops.sliders,
                detectionSliderConfig = sliderConfig.detectionSlider,
                speedSliderConfig = sliderConfig.speedSlider,
                autoCacheKey = '_auto',
                detectionFilterSliderCacheKey = '_detectionSlider',
                speedSliderCacheKey = '_speedSlider',
                fontSizeCacheKey = '_fontSize',
                $speedSlider = $('.speed-slider'),
                $detectionSlider = $('.detection-slider'),
                $distanceDropdownLabel = $('#distanceDropdownFilterLabel'),
                $speedDropdownLabel = $('#speedDropdownFilterLabel');
            
            $rootScope.$on('autoChange', function (e, newValue) {
                scope.showSpeedFilter = newValue;
                scope.detectionRange = newValue ?
                    _.range(detectionSliderConfig.basic.min + detectionSliderConfig.basic.step, sliderConfig.defaultDistanceFilter + 1, 1) :
                    _.range(detectionSliderConfig.basic.min + detectionSliderConfig.basic.step, detectionSliderConfig.basic.max + 1, detectionSliderConfig.basic.step);

                if ($detectionSlider[0].isConnected) {
                    $detectionSlider.slider('option', 'max', newValue ? sliderConfig.defaultDistanceFilter : detectionSliderConfig.basic.max).slider('pips', 'refresh');
                    $detectionSlider.slider('option', 'step', newValue ? 1 : detectionSliderConfig.basic.step).slider('pips', 'refresh');

                    if (newValue && $detectionSlider.slider('option', 'value') > sliderConfig.defaultDistanceFilter) {
                        setDetectionFilter(sliderConfig.defaultDistanceFilter);
                    }
                }
            });

            scope.fontSizeSelectorVisible = false;
            scope.isCompletedView = function() {
                return $state.current.name === 'main.completedRouteStops';
            }

            // font size value
            if ($rootScope.userType === 'underground'
                || $rootScope.userType === 'service') {
                scope.fontSizeSelectorVisible = true;
            }

            scope.selectFontSize = function (fontSize) {
                setFontSizeClass(fontSize);

                scope.selectedFontSize = fontSize;
                cacheService.set(fontSizeCacheKey, fontSize);
            };

            cacheService.get(fontSizeCacheKey).then(function (fontSize) {
                scope.selectedFontSize = fontSize || 'normal';

                setFontSizeClass(fontSize);
            });

            function setFontSizeClass(fontSize) {
                $('body').removeClass();
                $('body').addClass(`font-size-${fontSize}`);
            }

            // dropdown values
            scope.speedRange = _.range(speedSliderConfig.basic.min + speedSliderConfig.basic.step, speedSliderConfig.basic.max + 1, speedSliderConfig.basic.step);
            scope.detectionRange = _.range(detectionSliderConfig.basic.min + detectionSliderConfig.basic.step, detectionSliderConfig.basic.max + 1, detectionSliderConfig.basic.step);

            // slider values
            $detectionSlider.slider(detectionSliderConfig.basic).slider('pips', detectionSliderConfig.pips);
            $speedSlider.slider(speedSliderConfig.basic).slider('pips', speedSliderConfig.pips);

            cacheService.get(autoCacheKey).then(function (autoValue) {
                scope.showSpeedFilter = autoValue;
                $detectionSlider
                    .slider('option', 'max', autoValue ? sliderConfig.defaultDistanceFilter : detectionSliderConfig.basic.max)
                    .slider('option', 'step', autoValue ? 1 : detectionSliderConfig.basic.step);
            });

            // set values by cache
            cacheService.get(speedSliderCacheKey).then(function (speedSliderValue) {
                speedSliderValue = speedSliderValue || sliderConfig.defaultSpeedFilter;
                setSpeedFilter(speedSliderValue);
            });

            cacheService.get(detectionFilterSliderCacheKey).then(function (detectionSliderValue) {
                detectionSliderValue = detectionSliderValue || sliderConfig.defaultDistanceFilter;
                setDetectionFilter(detectionSliderValue);
            });

            // dropdown item click
            scope.speedFilterClick = function (n) {
                setSpeedFilter(n);
            };

            scope.distanceFilterClick = function (n) {
                setDetectionFilter(n);
            };

            // slider value set
            $speedSlider.on('slide', function (event, ui) {
                setSpeedFilter(ui.value, 'slider');
            });

            $detectionSlider.on('slide', function (event, ui) {
                setDetectionFilter(ui.value, 'slider');
            });

            // functions
            function setSpeedFilter(value, initiator) {
                cacheService.set(speedSliderCacheKey, value);
                $rootScope.speedFilter = value;
                $rootScope.$broadcast('speedSliderChange', value);

                if (initiator !== 'slider') $speedSlider.slider('option', 'value', value);
                $speedDropdownLabel.text(value + 'km/h');
            }

            function setDetectionFilter(value, initiator) {
                cacheService.set(detectionFilterSliderCacheKey, value);
                $rootScope.detectionFilter = value;
                $rootScope.$broadcast('detectionSliderChange', value);

                if (initiator !== 'slider') $detectionSlider.slider('option', 'value', value);
                $distanceDropdownLabel.text(value + 'm');
            }
        }

        function getIsUndegroundOrServiceUser() {
            return $rootScope.userType === 'service' || $rootScope.userType === 'underground';
        }
    }
})();

(() => {
  'use strict';
  const loopGuard = 200;

  angular.module('PWAPoCApp').factory('smsReceiptService', smsReceiptService);

  smsReceiptService.$inject = ['$q', 'cacheService', 'settingsService', 'receiptTemplateService'];

  function smsReceiptService($q, cacheService, settingsService, receiptTemplateService) {
      var cacheKey = 'smsReceiptTemplate';

      var service = {
          createSmsReceipt: createSmsReceipt
      };

      return service;

      function createSmsReceipt(routeStop) {
          var deferred = $q.defer();

          settingsService.getActiveDriver()
              .then(driver => {
                  var userData = {
                      routeStop,
                      driver
                  };

                  getSmsTemplate().then(template => {
                      var replacedTemplate = replaceUserDataInTemplate(template, userData);

                      deferred.resolve(replacedTemplate);
                  }, () => {
                      deferred.reject();
                  });
              }, () => {
                  deferred.reject();
              });

          return deferred.promise;
      }

      function replaceUserDataInTemplate(template, userData) {
          var routeStop = userData.routeStop;
          var address = routeStop.address ? routeStop.address : '';
          var driverName = userData && userData.driver && userData.driver.driverName ? userData.driver.driverName : '';
          var deviationText = routeStop.deviations && routeStop.deviations.length ? _.map(routeStop.deviations, d => d.deviation.text).join(', ') : '';
          var commentsText = routeStop.comments && routeStop.comments.length ? _.map(routeStop.comments, c => c.comment ? c.comment : '').join(', ') : '';
          var formattedTime = moment(routeStop.time, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm:ss');
          var agreementLineId = routeStop && routeStop.agreementLines && routeStop.agreementLines[0] && routeStop.agreementLines[0].agreementLineId;

          template = template.replace('(phAnNummer)', agreementLineId)
              .replace('(phAdresse)', address)
              .replace('(phTidspunkt)', formattedTime)
              .replace('(phNavnSjafor)', driverName)
              .replace('(phUtfortAv)', '')
              .replace('(phAvvik)##(phAvvik)', (' ' + deviationText))
              .replace('(phKommentarTekst)', commentsText);

          if (routeStop.dataButtons.length > 0 && routeStop.dataButtons[0].value && routeStop.dataButtons[0].valueNO) {
              template = template.replace('(phKubikData1)', routeStop.dataButtons[0].value)
                  .replace('(phKubikData1ButtonText)', routeStop.dataButtons[0].valueNO);
          }

          if (routeStop.dataButtons.length > 1 && routeStop.dataButtons[1].value && routeStop.dataButtons[1].valueNO) {
              template = template.replace('(phData2)', routeStop.dataButtons[1].value)
                  .replace('(phData2ButtonText)', routeStop.dataButtons[1].valueNO);
          }

          if (routeStop.dataButtons.length > 2 && routeStop.dataButtons[2].value && routeStop.dataButtons[2].valueNO) {
              template = template.replace('(phData3)', routeStop.dataButtons[2].value)
                  .replace('(phData3ButtonText)', routeStop.dataButtons[2].valueNO);
          }

          template = template
              .replace(/(\(.*\))/gim, '') //remove not replaced placeholders
              .replace(/(.*\:\s*\n)/gim, '') //remove rows with empty values
              .replace(/^(?:[\t\s]*(?:\r?\n|\r))+/gim, ''); //remove empty rows and whitespaces

          return template;
      }

      function getSmsTemplate() {
          var deferred = $q.defer();

          cacheService.has(cacheKey)
              .then(exists => {
                  if (exists) {
                      return cacheService.get(cacheKey);
                  } else {
                      return getTemplateFromServer();
                  }
              }).then(template => {
                  deferred.resolve(template);
              })
              .catch(() => {
                  deferred.reject();
              });

          return deferred.promise;
      }

      function getTemplateFromServer() {
          var deferred = $q.defer();

          receiptTemplateService.getReceiptTemplate(true)
              .then(template => {
                  var parsedTemplate = parseTemplateToSms(template);

                  cacheService.set(cacheKey, parsedTemplate);

                  deferred.resolve(parsedTemplate);
              }, () => {
                  deferred.reject();
              });

          return deferred.promise;
      }

      function parseTemplateToSms(template) {
          const textLineRegex = /(?:MULTI)?TEXT(?:\s\d+){4}\s(\S.*)/gim;
          const lines = [];
          let i = 0;
          let result = textLineRegex.exec(template);
          while (result && i < loopGuard) {
              lines.push(result[1]);
              result = textLineRegex.exec(template);
              ++i;
          }

          let parsedTemplate = lines.join('\r\n');
          parsedTemplate = parsedTemplate.replace(/(:\n\()/gim, ': ('); //remove linebreak before placeholders
          parsedTemplate = restorePrinterChars(parsedTemplate);

          return parsedTemplate;
      }

      function restorePrinterChars(input)
      {
          input = input.replace(/\[/g, 'Æ');
          input = input.replace(/\\/g, 'Ø');
          input = input.replace(/\]/g, 'Å');
          input = input.replace(/\{/g, 'æ');
          input = input.replace(/\|/g, 'ø');
          input = input.replace(/\}/g, 'å');

          return input;
      }
  }
})();

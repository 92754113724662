(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('callOrdersService', callOrdersService);

    callOrdersService.$inject = [
        '$rootScope',
        '$q',
        '$http',
        '$interval',
        'RouteStop',
        'locationService',
        'cacheService',
        'serviceUrls',
        'appSettings',
        'orderStatusTransitions',
        'authService',
        'appVersion'
    ];

    function callOrdersService(
        $rootScope,
        $q,
        $http,
        $interval,
        RouteStop,
        locationService,
        cacheService,
        serviceUrls,
        appSettings,
        orderStatusTransitions,
        authService,
        appVersion
    ) {
        const cacheKey = '_callOrders';
        const cachePrefix = '_routeStops_';
        let timer = null,
            lastPollFinishedAt = null,
            isPollRunning = false;

        let callOrdersService = {
            getAddedRouteStops,
            getCallOrder,
            getCallOrders,
            saveCallOrder,
            startPolling,
            stopPolling,
            updateCallOrderStatus
        };

        return callOrdersService;

        // Public interface
        function getAddedRouteStops(orderId) {
            var deferred = $q.defer();

            getCallOrders()
                .then(function (callOrders) {
                    var routeStops = _.flatten(_.map(_.filter(callOrders, { relatedOrderId: orderId }), 'routeStops'));
                    deferred.resolve(routeStops);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
            
        }

        function getCallOrder(orderId) {
            var deferred = $q.defer();

            cacheService.get(cacheKey).then(function (callOrders) {
                    var callOrder = _.find(callOrders, { order: { orderId: Number(orderId) } });
                    deferred.resolve(callOrder);
                })
                .catch(function (error) {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getCallOrders(refresh) {
            var deferred = $q.defer();

            var callOrders;
            var query = refresh ? getServerCallOrders() : cacheService.get(cacheKey);

            query
                .then(function (queryResult) {
                    callOrders = queryResult;

                    var getRouteStops = _.map(_.reject(callOrders, { transitionId: orderStatusTransitions.complete }), function (callOrder) {
                        return cacheService.get(cachePrefix + callOrder.order.orderId);
                    });

                    return $q.all(getRouteStops);
                })
                .then(function (routeStopsList) {
                    _.forEach(routeStopsList, function (routeStops, index) {
                        var callOrder = _.find(callOrders, { order: { orderId: routeStops.routeStops[0].callOrderId } });
                        callOrder.routeStops = _.map(routeStops.routeStops, function (routeStop) {
                            return _.merge(new RouteStop(), routeStop);
                        });
                    });

                    deferred.resolve(callOrders);
                })
                .catch(function (error) {
                    deferred.reject();
                });

            return deferred.promise;
        }
        
        function saveCallOrder(callOrder) {
            var deferred = $q.defer();

            var simpleCallOrder = _.cloneDeep(callOrder);
            if (simpleCallOrder.routeStops) delete simpleCallOrder.routeStops;

            cacheService.replaceIn(cacheKey, null, simpleCallOrder, 'order.orderId', simpleCallOrder.order.orderId).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function startPolling() {
            if (timer === null) {
                if ($rootScope.callOrderCheckInterval) {
                    getServerCallOrders();

                    timer = $interval(() => {
                        let shouldRunPoll = true;

                        //Avoid starting poll right after response returned if server response time is high
                        if (lastPollFinishedAt) {
                            if (moment().diff(lastPollFinishedAt, 'millisecond') < appSettings.callOrdersPollingIntervalMs / 3) {
                                shouldRunPoll = false;
                            }
                        }

                        if (shouldRunPoll && !isPollRunning) {
                            isPollRunning = true;
                            getServerCallOrders()
                                .then(() => lastPollFinishedAt = new Date())
                                .finally(() => isPollRunning = false);
                        }
                    }, appSettings.callOrdersPollingIntervalMs);
                }
            }
        }

        function stopPolling() {
            $interval.cancel(timer);
            timer = null;
            isPollRunning = false;
        }

        function updateCallOrderStatus(orderId, transitionId) {
            var deferred = $q.defer();

            cacheService.get(cacheKey)
                .then(function (callOrders) {
                    var callOrder = _.find(callOrders, { order: { orderId: Number(orderId) } });
                    callOrder.transitionId = transitionId;

                    return saveCallOrder(callOrder);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        // Private functions
        function getServerCallOrders() {
            let deferred = $q.defer();

            let position,
                callOrders,
                serverCallOrders,
                authData,
                newOrders;

            $http.get(serviceUrls.callOrders)
                .then(function (response) {
                    serverCallOrders = response.data;
                    return locationService.getCurrentPosition();
                })
                .then(function (currentposition) {
                    position = currentposition;
                    return authService.getAuthData();
                }).then(function (currentAuthData) {
                    authData = currentAuthData;
                    return cacheService.get(cacheKey);
                })
                .then(function (cahedCallOrders) {
                    callOrders = cahedCallOrders;
                    var cachedOrderIds = _.map(callOrders, 'order.orderId');
                    newOrders = _.reject(serverCallOrders, function (o) {
                        return _.includes(cachedOrderIds, o.order.orderId);
                    });

                    // TEST logic: purge test data
                    if (newOrders.length && newOrders[0].routelines[0].units.length > 1) {
                        newOrders[0].routelines[0].agreementLines = _.uniqWith(newOrders[0].routelines[0].agreementLines, _.isEqual);
                        newOrders[0].routelines[0].units = _.uniqWith(newOrders[0].routelines[0].units, _.isEqual);
                        newOrders[0].unitData = _.uniqWith(newOrders[0].unitData, _.isEqual);
                    }

                    var saveRouteStops = [];
                    _.forEach(newOrders, function (callOrder) {
                        callOrder.transitionId = null;
                        var route = { name: callOrder.order.routeName || callOrder.order.route };

                        route.routeStops = _.map(callOrder.routelines, function (routeline) {
                            var routeStop = new RouteStop(routeline, position, $rootScope.userSettings.dataButtons);
                            routeStop.customerId = authData.customerId;
                            routeStop.appVersion = appVersion;
                            routeStop.callOrderId = callOrder.order.orderId;
                            routeStop.sequence = -2;
                            routeStop.seen = false;

                            return routeStop;
                        });

                        delete callOrder.routelines;
                        saveRouteStops.push(cacheService.set(cachePrefix + callOrder.order.orderId, route));
                    });

                    return $q.all(saveRouteStops);
                })
                .then(function () {
                    if (callOrders && callOrders.length > 0) {
                        $rootScope.callOrderBadgeCount = callOrders.filter(o => !o.relatedOrderId).length;

                        if (callOrders.filter(o => !o.seen).length > 0) {
                            $rootScope.$broadcast('callOrderNotification');
                        }
                    } else {
                        $rootScope.callOrderBadgeCount = 0;
                    }

                    if (newOrders && newOrders.length) {
                        callOrders = _.concat(callOrders || [], newOrders);
                        return cacheService.appendTo(cacheKey, newOrders);
                    }
                })
                .then(function () {
                    deferred.resolve(callOrders);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();

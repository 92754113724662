(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('expirationService', expirationService);

    expirationService.$inject = ['$q', '$log', 'ordersService', 'routeStopsService', 'routeStopUpdateChecker', 'orderStatusTransitions', 'deviationService', 'cacheService'];

    function expirationService($q, $log, ordersService, routeStopsService, routeStopUpdateChecker, orderStatusTransitions, deviationService, cacheService) {
        var expirationService = {
            deleteExpiredOrders: deleteExpiredOrders,
            deleteOrphanedReportedRouteStops: deleteOrphanedReportedRouteStops,
            uploadOrphanedRoutes: uploadOrphanedRoutes,
            retryUploadingMarkedForUploads: retryUploadingMarkedForUploads
        };

        var routeStopsKey = '_routeStops_';

        return expirationService;

        function retryUploadingMarkedForUploads() {
            var deferred = $q.defer();

            var routeStopsWithOrderId;
            var uploadRequests = [];

            cacheService.getByPrefix(routeStopsKey)
                .then(routeCollection => {
                    if (routeCollection) {
                        routeStopsWithOrderId = getRouteStopsWithOrderId(routeCollection, 'markedForUpload');
                        if (routeStopsWithOrderId && routeStopsWithOrderId.length) {
                            return cacheService.get('_updateQueue');
                        } else {
                            deferred.resolve(0);
                        }
                    } else {
                        deferred.resolve(0);
                    }
                })
                .then((updateActions) => {
                    routeStopsWithOrderId.forEach(routeStopWithOrderId => {
                        if (updateActions && updateActions.length) {
                            //reject the ones which are in the update queue
                            routeStopWithOrderId.routeStops = _.reject(routeStopWithOrderId.routeStops, rs => _.find(updateActions, uA =>
                                uA.parameters[0] === routeStopWithOrderId.orderId
                                && uA.parameters[1] === rs.routeLineId));
                        }
                        uploadRequests = _.map(routeStopWithOrderId.routeStops, function (routeStopToUpload) {
                            return routeStopUpdateChecker.uploadRouteStop(routeStopWithOrderId.orderId, routeStopToUpload);
                        });
                    });
                    
                    return $q.all(uploadRequests);
                }).finally(() => {
                    deferred.resolve(uploadRequests.length);
                });;

            return deferred.promise;
        }

        function getRouteStopsWithOrderId(routeCollection, routeStopState) {
            var routeStopsWithOrder = [];
            for (var orderId in routeCollection) {
                if (Object.prototype.hasOwnProperty.call(routeCollection, orderId)) {
                    var route = routeCollection[orderId];
                    var markedForUploads = _.filter(route.routeStops, { status: routeStopState });
                    if (route && route.routeStops && route.routeStops.length && markedForUploads.length) {
                        routeStopsWithOrder.push({
                            orderId: orderId,
                            routeStops: markedForUploads
                        });
                    }
                }
            }
            return routeStopsWithOrder;
        }

        function uploadOrphanedRoutes() {
            var deferred = $q.defer();

            cacheService.getByPrefix(routeStopsKey)
                .then(routeCollection => {
                    for (var orderId in routeCollection) {
                        if (Object.prototype.hasOwnProperty.call(routeCollection, orderId)) {
                            var route = routeCollection[orderId];

                            if (route && route.routeStops && route.routeStops.length) {

                                var routeStopsToUpload = _.filter(route.routeStops, { status: 'completed' });

                                var uploadRequests = _.map(routeStopsToUpload, function (routeStopToUpload) {
                                    return routeStopUpdateChecker.uploadRouteStop(orderId, routeStopToUpload);
                                });

                                var setMarkedForUploads = _.map(routeStopsToUpload, function (routeStopToUpload) {
                                    return routeStopUpdateChecker.routeStopMarkedForUpload(orderId, routeStopToUpload);
                                });

                                $q.all(uploadRequests)
                                    .then(() => {
                                        return $q.all(setMarkedForUploads);
                                    })
                                    .finally(() => {
                                        deferred.resolve();
                                    });
                            }
                        } else {
                            deferred.resolve();
                        }
                    }
                });

            return deferred.promise;
        }

        function deleteOrphanedReportedRouteStops(orders) {

            var def = $q.defer();

            if (orders && orders.length > 0) {
                var currentOrderIds = _.map(orders, o => o.orderId);
                cacheService.getByPrefix('_reportedRouteStops_').then((orderIds) => {
                    if (orderIds) {
                        for (var reportedRouteStopsOrderId in orderIds) {
                            if (orderIds.hasOwnProperty(reportedRouteStopsOrderId)) {
                                if (_.findIndex(currentOrderIds, reportedRouteStopsOrderId) === -1) {
                                    //reported routeStops do not belong to the current orders
                                    cacheService.remove('_reportedRouteStops_' + reportedRouteStopsOrderId);
                                }
                            }
                        }
                    }
                    def.resolve();
                });
            } else {
                def.resolve();
            }
            return def;
        }

        function uploadCompletedRouteStopsForExpiredOrder(expiredOrder) {
            var deferred = $q.defer();

            var orderId = expiredOrder.orderId;
            cacheService.get('_routeStops_' + orderId)
                .then((data) => {
                    if (data && data.routeStops && data.routeStops.length > 0) {
                        var completedRouteStops = _.filter(data.routeStops, (rs) => rs.status === 'completed');
                        _.forEach(completedRouteStops, function (rs) {
                            $log.info('Expired order route stop added to update queue');
                            routeStopUpdateChecker.uploadRouteStop(orderId, rs).then(function () {
                                rs.status = 'markedForUpload';
                                routeStopsService.saveLocalRouteStop(orderId, rs);
                            });
                        });

                        var areAllRouteStopsReady = _.every(data.routeStops, function (routeStop) {
                            return isRouteStopReady(routeStop);
                        });

                        var hasUncompleted = _.some(data.routeStops, (rs) => rs.status === 'uncompleted');

                        if (areAllRouteStopsReady) {
                            var uploadedRouteStops = _.filter(data.routeStops, (rs) => rs.status === 'uploaded');
                            var latestRouteStopCompleted = _.sortBy(uploadedRouteStops, 'time')[0];
                            if (latestRouteStopCompleted && latestRouteStopCompleted.time) {
                                if (hasUncompleted) {
                                    ordersService.updateExpiredOrderStatus(orderId, orderStatusTransitions.cancel, latestRouteStopCompleted.time);
                                } else {
                                    ordersService.updateExpiredOrderStatus(orderId, orderStatusTransitions.complete, latestRouteStopCompleted.time);
                                }
                            }
                        }
                    }
                    deferred.resolve();
                });
            return deferred.promise;
        }

        function isRouteStopReady(routeStop) {
            return routeStop.status !== 'completed' && routeStop.status !== 'markedForUpload';
        }

        function deleteExpiredOrders() {
            var deferred = $q.defer();

            var expiredOrderIds;
            var deleteRouteResults = null;
            ordersService.getExpiredOrders()
                .then(function (expiredOrders) {
                    expiredOrderIds = _.map(expiredOrders, 'orderId');

                    var uploadCompletedRequests = _.map(expiredOrders, function (expiredOrder) {
                        return uploadCompletedRouteStopsForExpiredOrder(expiredOrder);
                    });

                    return $q.all(uploadCompletedRequests);
                }).then(function () {
                    //delete expired routes if all route stops are uploaded or not started
                    var deleteExpiredRoutesRequests = _.map(expiredOrderIds, function (expiredOrderId) {
                        return routeStopsService.deleteLocalRoute(expiredOrderId, function (route) {
                            return !route || !route.routeStops || _.every(route.routeStops, function (routeStop) {
                                return isRouteStopReady(routeStop);
                            });
                        });
                    });
                    return $q.all(deleteExpiredRoutesRequests);
                }).then(function (deleteResults) {
                    //if routes were deleted successfully delete order
                    deleteRouteResults = deleteResults;
                    var deleteOrderRequests = _.map(_.filter(expiredOrderIds, function (expiredOrderId, index) {
                        return deleteRouteResults[index];
                    }), function (deletedRouteOrderId) {
                        $log.warn('order deleted by system' + deletedRouteOrderId);
                        return [ordersService.deleteOrder(deletedRouteOrderId), deviationService.removeImageFromCache(deletedRouteOrderId)];
                    });

                    deleteOrderRequests = _.flatten(deleteOrderRequests);
                    return $q.all(deleteOrderRequests);
                }).then(function () {
                    //if routes were deleted successfully reported route stops
                    var deleteReportedRouteStops = _.map(_.filter(expiredOrderIds, function (expiredOrderId, index) {
                        return deleteRouteResults[index];
                    }), function (expiredOrderId) {
                        return cacheService.remove('_reportedRouteStops_' + expiredOrderId);
                    });
                    return $q.all(deleteReportedRouteStops);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('progressBar', progressBar);

    progressBar.$inject = [];

    function progressBar() {
        var directive = {
            replace: true,
            restrict: 'E',
            scope: {
                display: '=',
                value: '='
            },
            templateUrl: 'app/shared/directives/progress-bar.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
        }
    }
})();

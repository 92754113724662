(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('routeStopRowAjour', routeStopRowAjour);

    routeStopRowAjour.$inject = [];

    function routeStopRowAjour() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/route-stop-row-ajour.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            const orderItemTypes = {
                unknown: 0,
                in: 1,
                out: 2,
                replace: 3,
                disposal: 4,
                rep: 5,
                bef: 6,
                inspection: 7,
                service: 8,
            }

            scope.getRouteStopIcon = function (routeStop) {
                if (routeStop && routeStop.units && routeStop.units.length) {
                    var first = routeStop.units[0].orderItemType;
                    if ((first === orderItemTypes.in && _.some(routeStop.units, u => u.orderItemType === orderItemTypes.out))
                        || (first === orderItemTypes.out && _.some(routeStop.units, u => u.orderItemType === orderItemTypes.in))) {
                        return scope.getIcon(orderItemTypes.replace);
                    } else {
                        return scope.getIcon(first);
                    }
                }
                return scope.getIcon(orderItemTypes.unknown);
            }

            scope.getIcon = function (orderType) {
                orderType = orderType ? orderType : '';
                switch (orderType) {
                    case orderItemTypes.in: {
                        return "fa fa-long-arrow-alt-left fa-1-25x";
                    }
                    case orderItemTypes.out: {
                        return "fa fa-long-arrow-alt-right fa-1-25x";
                    }
                    case orderItemTypes.replace: {
                        return "fa fa-exchange-alt fa-1-25x";
                    }
                    case orderItemTypes.rep: {
                        return "fa fa-wrench fa-1-25x";
                    }
                    case orderItemTypes.inspection: {
                        return "fa fa-search fa-1-25x";
                    }
                    case orderItemTypes.service: {
                        return "fa fa-toolbox fa-1-25x";
                    }
                    default: {
                        return 'fa fa-question fa-1-25x';
                    }
                }
            }

            scope.splitSource = function (sourceItems, index) {
                var endInd = sourceItems.length / 2;
                var part = index === 0 ? sourceItems.slice(0, endInd) : sourceItems.slice(endInd, sourceItems.length);
                return part;
            }

            scope.displayFilter = function (sourceItems) {
                return _.reject(sourceItems, s => s.key === "DetailData_Address" || s.key === "DetailData_Message");
            }

            scope.filterForMessage = function (orderData) {
                return _.filter(orderData, { key: 'DetailData_Message' });
            }
        }
    }
})();

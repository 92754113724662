(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('routeStopRowDefault', routeStopRowDefault);

    routeStopRowDefault.$inject = [];

    function routeStopRowDefault() {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/route-stop-row-default.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
        }
    }
})();

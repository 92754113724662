(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('ErrorController', errorController);

    errorController.$inject = ['$scope'];

    function errorController($scope) {
        $scope.errorMessage = '';

        initController();

        function initController() {
            $scope.errorMessage = 'Fullversjon av PWA krever en annen nettleser.';
        }
    }
})();

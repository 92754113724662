(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('logService', logService);

    logService.$inject = ['$q', 'cacheService', 'appSettings'];

    function logService($q, cacheService, appSettings) {
        var logService = {
            deleteLogs: deleteLogs,
            deleteOldLogs: deleteOldLogs
        };

        return logService;

        function deleteLogs() {
            var deferred = $q.defer();

            cacheService.get('_log').then(logs => {
                var retainedLogs = _.orderBy(_.filter(logs, { logLevel: "error" }), 'timestampUtc', ['desc']).slice(0, 10);
                if (retainedLogs && retainedLogs.length) {
                    cacheService.set('_log', retainedLogs).then(function () {
                        deferred.resolve();
                    }, function () {
                        deferred.resolve();
                    });
                } else {
                    cacheService.remove('_log').then(function () {
                        deferred.resolve();
                    }, function () {
                        deferred.resolve();
                    });
                }

            });

            return deferred.promise;
        }

        function deleteOldLogs() {
            var deferred = $q.defer();

            cacheService.get('_log').then(logs => {
                if (logs) {
                    var retainedLogs = _.orderBy(_.filter(logs, { logLevel: "error" }), 'timestampUtc', ['desc']).slice(0, 10);

                    var filterTime = moment().subtract(appSettings.deleteOldLogsIntervalMs, 'ms').toISOString();
                    var filteredRetainedLogs = _.filter(retainedLogs, log => log.timestampUtc < filterTime);

                    cacheService.removeFromBy('_log', null, 'timestampUtc', filterTime, 'lt').then(function () {
                        if (filteredRetainedLogs && filteredRetainedLogs.length) {
                            cacheService.addTo('_log', filteredRetainedLogs).then(() => {
                                deferred.resolve();
                            });
                        } else {
                            deferred.resolve();
                        }
                    }, function () {
                        deferred.resolve();
                    });
                } else {
                    deferred.resolve();
                }
            });

            return deferred.promise;
        }
    }
})();

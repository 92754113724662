(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('MainController', mainController);

    mainController.$inject = ['$scope'];

    function mainController($scope) {
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('CameraModalController', cameraModalController);

    cameraModalController.$inject = ['$q', '$scope', '$rootScope', '$windowInstance', '$timeout', '$log', 'videoMode', 'image', 'viewPhotoOnly', 'enableImageModalOpenCallback'];

    function cameraModalController($q, $scope, $rootScope, $windowInstance, $timeout, $log, videoMode, image, viewPhotoOnly, enableImageModalOpenCallback) {
        var videoId = 'avvikVideoDiv',
            canvasId = 'takePhotoCanvas',
            imageInputId = 'image-upload',
            stream = null;

        $scope.photoButtonText = 'Ta et bilde';
        $scope.isPhotoVisible = false;
        $scope.isPhoneOrTablet = null;
        var photoTaken = false;

        $scope.viewPhotoOnly = viewPhotoOnly;

        $scope.hasImage = function () {
            return image || photoTaken;
        }

        $scope.close = function () {
            stopStream();
            $windowInstance.close();
        };
        $rootScope.$on('deviationWindowClosed',
            () => {
                $windowInstance.close(false);
            });

        $scope.save = function () {
            if ($scope.hasImage()) {
                var canvas = document.getElementById(canvasId);
                var imageSource = videoMode ? canvas.toDataURL() : URL.createObjectURL(image);
                imageResizeAndConvertToBase64(imageSource).then(function (imgBase64) {
                    stopStream();
                    $windowInstance.close(imgBase64);
                }, function () {
                    // error occurred
                });
            }
        };

        $scope.takePhoto = function () {
            photoTaken = true;
            if (videoMode) {
                if (!$scope.isPhotoVisible) {
                    var canvas = document.getElementById(canvasId);
                    var video = document.getElementById(videoId);

                    setMode(true);
                    $timeout(0).then(function () {
                        drawVideoToCanvas(canvas, video);
                    });
                } else {
                    setMode(false);
                }
            } else {
                var imageInput = document.getElementById(imageInputId);
                imageInput.click();
                imageInput.off().on('change', function (e) {
                    var image = e.target.files[0];
                    if (image) {
                        drawImage(image);
                    }
                });
            }
        };

        initController();

        function initController() {
            $timeout(enableImageModalOpenCallback, 200);
            if (videoMode) {
                if (Modernizr.getusermedia) {
                    $timeout(250).then(function () {
                        navigator.mediaDevices.getUserMedia({ video: { facingMode: { ideal: "environment" } } }).then(function (mediaStream) {
                            stream = mediaStream;
                            var videoDocument = document.querySelector('video');
                            if (videoDocument) {
                                videoDocument.srcObject = stream;
                            } else {
                                $log.error("video element is not found in document");
                            }
                        }, function (error) {
                            $log.error(error);
                        });
                    });
                }

                if (image) {
                    setMode(true);
                    $timeout(100).then(drawImage);
                }
            } else {
                setMode(true);
                $timeout(100).then(drawImage);
            }
        }

        function setMode(isPhotoMode) {
            isPhotoMode = isPhotoMode || !$scope.isPhotoVisible;

            $scope.isPhotoVisible = isPhotoMode;
            $scope.photoButtonText = isPhotoMode ? 'Gjenta et bilde' : 'Ta et bilde'; //ta et bilde - take picture, Gjenta - retake picture
        }

        function imageResizeAndConvertToBase64(imageSrc) {
            var deferred = $q.defer();

            var imageElement = new Image();

            imageElement.onload = function () {
                var ratio, maxSize = 800;

                if (imageElement.width > maxSize) {
                    ratio = maxSize / imageElement.width;

                    imageElement.width = imageElement.width * ratio;
                    imageElement.height = imageElement.height * ratio;
                }

                if (imageElement.height > maxSize) {
                    ratio = maxSize / imageElement.height;

                    imageElement.width = imageElement.width * ratio;
                    imageElement.height = imageElement.height * ratio;
                }

                var canvas = document.createElement("canvas");
                canvas.width = imageElement.width;
                canvas.height = imageElement.height;
                canvas.getContext("2d").drawImage(imageElement, 0, 0, imageElement.width, imageElement.height);
                deferred.resolve(canvas.toDataURL('image/jpeg'));
                canvas.remove();
            };

            imageElement.onerror = function () {
                deferred.reject();
            };

            //Start loading the image
            imageElement.src = imageSrc;

            return deferred.promise;
        }

        function drawImageToCanvas(canvas, img, orientation) {
            var widthHeightChange = orientation > 4;
            var imageWidth = widthHeightChange ? img.height : img.width;
            var imageHeight = widthHeightChange ? img.width : img.height;

            drawCanvas(canvas, img, imageWidth, imageHeight, orientation);
        }

        function drawVideoToCanvas(canvas, video) {
            drawCanvas(canvas, video, video.videoWidth, video.videoHeight);
        }

        function drawCanvas(canvas, object, width, height, orientation) {
            canvas.width = getComputedStyle(canvas).width.split('px')[0];
            canvas.height = getComputedStyle(canvas).height.split('px')[0];

            var ratio = Math.min(canvas.width / width, canvas.height / height);
            var x = (canvas.width - width * ratio) / 2;
            var y = (canvas.height - height * ratio) / 2;

            var ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.save();

            if (orientation) {
                loadImage.transformCoordinates(canvas, { orientation: orientation });
            }

            ctx.drawImage(object, 0, 0, width, height, x, y, width * ratio, height * ratio);
            ctx.restore();
        }

        function drawImage() {
            $timeout(function () {
                loadImage.parseMetaData(
                    image,
                    function (data) {
                        var imageElement = new Image();
                        imageElement.src = _.startsWith(image, 'data:image/') ? image : URL.createObjectURL(image);
                        imageElement.onload = function () {
                            var canvas = document.getElementById(canvasId);
                            var orientation = data && data.exif ? data.exif[0x0112] : null;

                            drawImageToCanvas(canvas, imageElement, orientation);
                        };
                    }
                );
            }, 100);
        }

        function stopStream() {
            if (stream) {
                _.forEach(stream.getTracks(), function (track) {
                    track.stop();
                });
            }
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('imageService', imageService);

    imageService.$inject = ['$q', '$http', 'serviceUrls'];

    function imageService($q, $http, serviceUrls) {
        var service = {
            uploadImage: uploadImage
        };

        return service;

        function uploadImage(imageHash) {
            var deferred = $q.defer();
            const url = serviceUrls.imageUrl + '/content-only';

            if (imageHash && imageHash.indexOf('data:image') !== -1) {
                var content = imageHash.substr(imageHash.indexOf(',') + 1);

                $http.post(url, {
                    base64EncodedContent: content,
                    extension: 'jpg'
                }).then(function(response) {
                    deferred.resolve(response.data);
                }).catch(function() {
                    deferred.reject();
                });
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('dataButtonValidationService', dataButtonValidationService);

    dataButtonValidationService.$inject = ['$q', 'settingsService'];

    function dataButtonValidationService($q, settingsService) {
        return {
            validateDataButtons
        };

        //Validates all data buttons, but returns with the very first error found
        function validateDataButtons(routeStop) {
            var deferred = $q.defer();

            //No need to check validation rules if there is no data button
            if (!routeStop.dataButtons) {
                deferred.resolve({ hasError: false });
            } else {
                settingsService.getDataButtonValidationRules(routeStop).then(validationRules => {
                    let hasError = false,
                        errorMessage = '';

                    for (let dataButton of routeStop.dataButtons) {
                        let currentRule = getRulesForButton(validationRules, dataButton.parameter);

                        if (currentRule) {
                            //Check profile
                            if (currentRule.profileTypes && currentRule.profileTypes.length > 0) {
                                if (currentRule.profileTypes.find(p => p === routeStop.wasteType)) {
                                    if (!dataButton.value) {
                                        hasError = true;

                                        if (currentRule.isRequiredOrDeviation) {
                                            errorMessage = `${dataButton.valueNO} eller avvik er påkrevd!`;
                                        } else {
                                            errorMessage = `${dataButton.valueNO} er påkrevd`;
                                        }

                                        break;
                                    }
                                }
                            }
                        }
                    }

                    deferred.resolve({ hasError, errorMessage });
                });
            }

            return deferred.promise;
        }

        //Private functions
        function getRulesForButton(validationRules, parameter) {
            if (!validationRules || !parameter) {
                return null;
            }

            if (parameter.length > 10) {
                let buttonNumber = Number(parameter.substring(10)),
                    index = (buttonNumber-1)*2 + 1;
                if (validationRules.length > index) {
                    let profileTypes = [];
                    if (validationRules[index - 1]) {
                        profileTypes = validationRules[index-1].split('|');
                    }

                    return {
                        profileTypes: profileTypes,
                        isRequiredOrDeviation: validationRules[index]
                    };
                }
            }

            return null;
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('logger', logger);

    logger.$inject = ['$injector', 'appSettings'];

    function logger($injector, appSettings) {

        var logger = function ($delegate) {
            var minLevel = appSettings.minimumLogLevel;
            return {
                debug: function () {
                    $delegate.debug.apply(null, arguments);
                    if (minLevel < 1 || (minLevel < 2 && isExtendedInfo(arguments[0]))) cacheLog('debug', [].slice.call(arguments));
                },
                error: function () {
                    $delegate.error.apply(null, arguments);
                    if (minLevel < 5) cacheLog('error', [].slice.call(arguments));
                },
                info: function () {
                    $delegate.info.apply(null, arguments);
                    if (minLevel < 3) cacheLog('info', [].slice.call(arguments));
                },
                log: function () {
                    $delegate.log.apply(null, arguments);
                    if (minLevel < 3) cacheLog('info', [].slice.call(arguments));
                },
                warn: function () {
                    $delegate.warn.apply(null, arguments);
                    if (minLevel < 4) cacheLog('warn', [].slice.call(arguments));
                }
            }
        };

        return logger;

        function cacheLog(logLevel, message) {
            var logItem = {
                logLevel: logLevel,
                message: message,
                timestampUtc: moment.utc().format()
            };

            var cacheService = $injector.get('cacheService');
            cacheService.appendTo('_log', logItem);
        };

        //Private functions
        function isExtendedInfo(log) {
            var excludedEvents = ['[AngularJS - Leaflet]', 'leafletDirectiveMarker', 'leafletDirectiveMap', 'LeafletEventsHelpersFactory', 'kendoRendered', 'kendoWidgetCreated',
                '$viewContentAnimationEnded', '$viewContentLoading', '$viewContentLoaded'];
            var excludedUrls = ['api/ping', 'api/auth', 'api/message/directmessages'];

            var notExtendedInfo = _.some(excludedEvents, function (excludedEvent) {
                    return log && ((_.isArguments(log) && log[0].indexOf(excludedEvent) > -1) || (_.isString(log) && log.indexOf(excludedEvent) > -1));
                }) ||
                _.some(excludedUrls, function (url) {
                    return log && log.url && log.url.indexOf(url) > -1;
                });

            return !notExtendedInfo;
        }
    }
})();

(function () {
    'use strict';

    let pwaPoCApp = angular.module('PWAPoCApp');

    pwaPoCApp.run(updateActionTypeSetup);

    updateActionTypeSetup.$inject = [
        '$q',
        'routeStopsService',
        'ordersService',
        'messagesService',
        'imageService',
        'updateQueue'
    ];

    function updateActionTypeSetup(
        $q,
        routeStopsService,
        ordersService,
        messagesService,
        imageService,
        updateQueue
    ) {
        let updateActionTypes = [
            {
                type: 'updateRouteStop',
                callback: routeStopsService.updateRouteStopRequest
            },
            {
                type: 'updateOrderStatus',
                callback: ordersService.updateOrderStatusRequest
            },
            {
                type: 'deleteRoute',
                callback: routeStopsService.deleteRouteRequest
            },
            {
                type: 'deleteOrder',
                callback: ordersService.deleteOrderRequest
            },
            {
                type: 'updateMessageStatus',
                callback: messagesService.updateMessageStatusRequest
            },
            {
                type: 'sendMessage',
                callback: messagesService.sendMessageRequest
            },
            {
                type: 'uploadImage',
                callback: uploadImageRequest
            }
        ];

        updateQueue.setUpdateActionTypes(updateActionTypes);

        function uploadImageRequest(orderId, routeLineId, agreementLineIds, imageHash) {
            let deferred = $q.defer();

            let uploadedPictureUrl;
            imageService.uploadImage(imageHash)
                .then(function (pictureUrl) {
                    uploadedPictureUrl = pictureUrl;
                    return routeStopsService.getLocalRouteStop(orderId, routeLineId);
                })
                .then(function (routeStop) {
                    _.forEach(agreementLineIds, function (agreementLineId) {
                        routeStop.deviationPictures.push({ agreementLineId: agreementLineId, pictureUrl: uploadedPictureUrl });
                    });
                    return routeStopsService.saveLocalRouteStop(orderId, routeStop);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();

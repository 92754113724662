(function() {
    angular.module('PWAPoCApp').controller('DataButtonModalController', dataButtonModalController);

    dataButtonModalController.$inject = ['$timeout', '$scope', '$windowInstance', 'dataButton'];

    function dataButtonModalController($timeout, $scope, $windowInstance, dataButton) {
        $scope.numbers = '';
        $scope.commaDisabled = dataButton.commaDisabled;
        
        $scope.addComma = addComma;
        $scope.close = close;
        $scope.save = save;
        
        initController();

        function initController() {
            if (dataButton.value) {
                $scope.numbers = dataButton.value;
            }

            $timeout(400).then(function(){
                const valueDiv = document.querySelector('.value');
                const node = document.createElement('div');
                const icon = document.createElement('i');

                icon.classList.add('fas', 'fa-undo');
                node.classList.add('reset-button');
                node.appendChild(icon);
                valueDiv.appendChild(node);

                node.addEventListener('click', function(e){
                    $scope.numbers = '';
                })
            })
        }

        function addComma() {
            if ($scope.numbers.indexOf(',') === -1) {
                $scope.numbers += ',';
            }
        }

        function close() {
            $windowInstance.close({
                action: 'cancel',
                value: null
            });
        }

        function save() {
            $windowInstance.close({
                action: 'save',
                value: $scope.numbers
            });
        }
    }
})();

(function () {
    angular.module('PWAPoCApp').constant('locale',
        {
            error_template: "Ugyldig mal",
            error_userData: "Ugyldig brukerdata",
            error_adjustVerticalSpacing: "Tilpass vertikal skrift",
            error_createReceipt: "Opprett utskrift",
            error_connectingPrinter: "Kobler til printer",
            error_printerUnavailable: "Printeren er utilgjengelig",
            error_sendingData: "Sender data",
            error_establishingConnection: " Etablerer kobling",
            error_printerIsBusy: "Printeren er opptatt",
            error_addressError: " Adressefeil",
            error_fillingTemplateData: "Fyller maldata"
        });
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('routeStopsService', routeStopsService);

    routeStopsService.$inject = ['$q', '$http', '$rootScope', '$log', 'serviceUrls', 'authService', 'cacheService', 'RouteStop', 'commonUtil', 'updateQueue', 'appVersion'];

    function routeStopsService($q, $http, $rootScope, $log, serviceUrls, authService, cacheService, RouteStop, commonUtil, updateQueue, appVersion) {
        var cachePrefix = '_routeStops_',
            getRouteLocks = {},
            getRouteCalls = {},
            getReportedRouteLocks = {},
            getReportedRouteCalls = {},
            reportedRouteCachePrefix = '_reportedRouteStops_',
            routeStopFilterByCachePrefix = '_routeStopFilterBy',
            routeStopsSplitViewStatusCachePrefix = 'routeStops_splitViewStatus',
            routeStopSortByCachePrefix = '_routeStopSortBy',
            hubConnection;

        var routeStopFilterType, routeStopsSplitViewStatus, routeStopSortType;

        var routeStopsService = {
            deleteLocalRoute: deleteLocalRoute,
            deleteRouteRequest: deleteRouteRequest,
            getContainerDetails: getContainerDetails,
            getFilterBy: getFilterBy,
            getCachedFilterBy: getCachedFilterBy,
            getLocalRouteStop: getLocalRouteStop,
            getReportedRoute: getReportedRoute,
            getRoute: getRoute,
            getRouteLinesInRadius: getRouteLinesInRadius,
            getSplitViewStatus: getSplitViewStatus,
            getSortBy: getSortBy,
            queueRouteDelete: queueRouteDelete,
            saveFilterBy: saveFilterBy,
            saveLocalRouteStop: saveLocalRouteStop,
            saveReportedRouteStops: saveReportedRouteStops,
            saveSplitViewStatus: saveSplitViewStatus,
            saveSortBy: saveSortBy,
            updateRouteStop: updateRouteStop,
            updateRouteStopRequest: updateRouteStopRequest,
            addRouteStopToLocalRoute: addRouteStopToLocalRoute,
            updateRouteStopCoords: updateRouteStopCoords,
            updateUnitCoordinates: updateUnitCoordinates
        };

        initService();

        return routeStopsService;

        function initService() {
            getCachedFilterBy().then(fType => {
                routeStopFilterType = fType;
            });

            cacheService.get(routeStopsSplitViewStatusCachePrefix).then(function (splitViewStatus) {
                routeStopsSplitViewStatus = splitViewStatus || 'horizontal';
            });

            cacheService.get(routeStopSortByCachePrefix).then(function (sortType) {
                routeStopSortType = sortType || 'distance';
            });
        }

        function getCachedFilterBy() {
            var deferred = $q.defer();
            cacheService.get(routeStopFilterByCachePrefix).then(function (filterType) {
                deferred.resolve(filterType || 'description');
            });

            return deferred.promise;
        }

        function updateRouteStopCoords(orderId, routeStop) {
            var deferred = $q.defer();

            $http.put('api/routeStops/adjustposition/' + routeStop.routeLineId, { longitude: routeStop.longitude, latitude: routeStop.latitude }).then(function (response) {
                if (response && response.data) {
                    return updateRouteStop(orderId, routeStop);
                } else {
                    return $q.reject();
                }
            }).then(function () {
                deferred.resolve();
            }).catch(function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function deleteLocalRoute(orderId, guardFunction) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            var isDeleted = false;
            cacheService.get(cacheKey)
                .then(function (route) {
                    if (guardFunction(route)) {
                        isDeleted = true;
                        return cacheService.remove(cacheKey);
                    }
                })
                .then(function () {
                    deferred.resolve(isDeleted);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function deleteRouteRequest(orderId) {
            return deleteRoute(orderId);
        }

        function getContainerDetails(orderId, containerId) {
            var deferred = $q.defer();

            $http.get('api/orders/' + orderId + '/containerDetails/' + containerId).then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;

        }

        function getFilterBy() {
            return routeStopFilterType;
        }

        function addRouteStopToLocalRoute(routeStop, orderId) {
            
            var cacheKey = cachePrefix + orderId;
            var deferred = $q.defer();


            cacheService.addToIn(cacheKey, routeStop, true, 'routeStops').then(function() {
                deferred.resolve();
            },
                function(err) {
                    deferred.reject();
                });
            return deferred.promise;
        }

        function getLocalRouteStop(orderId, routeStopId) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            cacheService.get(cacheKey)
                .then(function (route) {
                    var routeStop = route ? _.find(route.routeStops, { "routeLineId": routeStopId }) : null;
                    deferred.resolve(routeStop);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getReportedRoute(orderId, refreshCache) {
            var deferred = $q.defer();
            var key = orderId;

            if (!getReportedRouteLocks[key]) {
                getReportedRouteLocks[key] = true;

                var reportedRoute;
                var cacheKey = reportedRouteCachePrefix + orderId;

                var request = refreshCache === true
                    ? $http.get('api/orders/' + orderId + '/reportedroute')
                    : Promise.resolve();

                request
                    .then(function (response) {
                        if (response && response.data) {
                            reportedRoute = response.data;
                            return saveReportedRouteStops(orderId, reportedRoute);
                        }
                    })
                    .catch(function () {
                        // ignore
                    })
                    .then(function () {
                        return reportedRoute || cacheService.get(cacheKey);
                    })
                    .then(function (reportedRoute) {
                        if (reportedRoute) {
                            deferred.resolve(reportedRoute);
                        } else {
                            deferred.reject();
                        }
                    })
                    .catch(function () {
                        deferred.reject();
                    })
                    .finally(function () {
                        getReportedRouteLocks[key] = false;
                        var getReportedRouteCall = getReportedRouteCalls[key] ? getReportedRouteCalls[key].pop() : null;

                        if (getReportedRouteCall) {
                            getReportedRoute.apply(null, getReportedRouteCall.args).then(function (result) {
                                getReportedRouteCall.deferred.resolve(result);
                            }, function () {
                                getReportedRouteCall.deferred.reject();
                            });
                        }

                        deferred.resolve(reportedRoute);
                    });
            } else {
                getReportedRouteCalls[key] = getReportedRouteCalls[key] || [];
                getReportedRouteCalls[key].unshift({ args: arguments, deferred: deferred });
            }

            return deferred.promise;
        }

        function getRoute(orderId, position) {
            var deferred = $q.defer();
            var key = orderId;

            if (!getRouteLocks[key]) {
                getRouteLocks[key] = true;
                var prefix = cachePrefix + orderId;
                var route;

                cacheService.has(prefix)
                    .then(function (exists) {
                        if (exists) {
                            return cacheService.get(prefix);
                        } else {
                            return getServerRoute(orderId, position);
                        }
                    })
                    .then(function (route) {
                        route.routeStops = _.map(route.routeStops, function (routeStop) {
                            return _.merge(new RouteStop(), routeStop);
                        });
                        deferred.resolve(route);
                    })
                    .catch(function () {
                        deferred.reject();
                    })
                    .finally(function () {
                        getRouteLocks[key] = false;
                        var getRouteCall = getRouteCalls[key] ? getRouteCalls[key].pop() : null;

                        if (getRouteCall) {
                            getRoute.apply(null, getRouteCall.args).then(function (result) {
                                getRouteCall.deferred.resolve(result);
                            }, function () {
                                getRouteCall.deferred.reject();
                            });
                        }

                        deferred.resolve(route);
                    });
            } else {
                getRouteCalls[key] = getRouteCalls[key] || [];
                getRouteCalls[key].unshift({ args: arguments, deferred: deferred });
            }

            return deferred.promise;
        }

        function getRouteLinesInRadius(radius, maxHits, lat, lng) {
            var deferred = $q.defer();

            var proximityDto = {
                radius: radius,
                maxHits: maxHits,
                latitude: lat,
                longitude: lng
            };

            var qStr = '?'+$.param(proximityDto);
            $http.get(serviceUrls.orders + '/routelinesinradius/' + qStr).then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getSplitViewStatus() {
            return routeStopsSplitViewStatus;
        }

        function getSortBy() {
            return routeStopSortType;
        }

        function saveFilterBy(filterBy) {
            routeStopFilterType = filterBy;

            cacheService.set(routeStopFilterByCachePrefix, filterBy);
        }

        function saveLocalRouteStop(orderId, routeStop) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            cacheService.replaceIn(cacheKey, 'routeStops', routeStop, 'routeLineId', routeStop.routeLineId).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function saveReportedRouteStops(orderId, reportedRouteStops) {
            var deferred = $q.defer();

            reportedRouteStops = _.uniqBy(reportedRouteStops, 'routeLineId');

            cacheService.set(reportedRouteCachePrefix + orderId, reportedRouteStops).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function saveSplitViewStatus(splitViewStatus) {
            routeStopsSplitViewStatus = splitViewStatus;

            cacheService.set(routeStopsSplitViewStatusCachePrefix, splitViewStatus);
        }

        function saveSortBy(sortBy) {
            routeStopSortType = sortBy;

            cacheService.set(routeStopSortByCachePrefix, sortBy);
        }

        function queueRouteDelete(orderId) {
            var deferred = $q.defer();

            var uploadAction = {
                id: commonUtil.generateGuid(),
                parameters: [orderId],
                type: 'deleteRoute'
            };

            updateQueue.addUpdateAction(uploadAction)
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function updateRouteStop(orderId, routeStop) {
            var deferred = $q.defer();

            saveLocalRouteStop(orderId, routeStop).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function updateRouteStopRequest(orderId, routeStopId) {
            var deferred = $q.defer();

            getLocalRouteStop(orderId, routeStopId)
                .then(function (routeStop) {
                    if (routeStop) {
                        $http.put(serviceUrls.orders + '/' + orderId + '/routeStops', [routeStop])
                            .then(function () {
                                routeStop.status = 'uploaded';
                                return saveLocalRouteStop(orderId, routeStop);
                            })
                            .then(function () {
                                $rootScope.$broadcast('routeStopUploaded', routeStop);
                                deleteLocalRoute(orderId,
                                    function (route) {
                                        return _.every(route.routeStops, { status: 'uploaded' });
                                    });
                                deferred.resolve();
                            })
                            .catch(function () {
                                deferred.reject();
                            });
                    } else {
                        $log.error('Cannot upload route stop (id: ' + routeStopId + ') because there is no local route (order: ' + orderId + ')');
                        deferred.resolve();
                    }
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        // private functions
        function deleteRoute(orderId) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            cacheService.remove(cacheKey).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getServerRoute(orderId, position) {
            var deferred = $q.defer();

            var route;
            authService.getAuthData('authData').then(function (authData) {
                if (authData) {
                    var options = {
                        logging: signalR.LogLevel.Information,
                        accessTokenFactory: function () {
                            return authData.token;
                        }
                    };

                    hubConnection = new signalR.HubConnectionBuilder()
                        .withUrl('routehub?customerid=' + authData.customerId, options)
                        .build();

                    hubConnection.on("retryTriggered", function () {
                        $rootScope.$broadcast('getRouteRetryTriggered');
                    });

                    hubConnection.start()
                        .then(function () {
                            return hubConnection.invoke("GetRouteWithDynamicOrderData", orderId);
                        })
                        .then(function (response) {
                            if (response) {
                                route = {
                                    name: response.name,
                                    routeStops: _.map(response.routelines,
                                        function (routeline) {
                                            var routeStop = new RouteStop(routeline,
                                                position,
                                                $rootScope.userSettings.dataButtons
                                            );
                                            routeStop.customerId = authData.customerId;
                                            routeStop.appVersion = appVersion;
                                            return routeStop;
                                        })
                                };
                                return cacheService.set(cachePrefix + orderId, route);
                            }
                        })
                        .then(function () {
                            hubConnection.stop();
                            if (route) {
                                deferred.resolve(route);
                            } else {
                                deferred.reject();
                            }
                        })
                        .catch(function () {
                            deferred.reject();
                        });
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function updateUnitCoordinates(agreementLineId, paSystem, x, y) {
            var deferred = $q.defer();

            $http.put('api/units/adjustposition/' + agreementLineId, { longitude: x, latitude: y, paSystem: paSystem })
            .then(function (response) {
                if (response && response.status === 200) {
                    deferred.resolve();
                } else {
                    deferred.reject();
                }
            })
            .catch(function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();

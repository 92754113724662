(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('tiledmapLayer', tiledmapLayer);

    tiledmapLayer.$inject = ['leafletData'];

    function tiledmapLayer(leafletData) {
        var directive = {
            require: '?leaflet',
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, element, attrs, ctrl) {
            var options = scope.tiledmapLayer;
            var crs = new L.Proj.CRS(scope.projection.code, scope.projection.proj4def, scope.projection.options);

            if (options) {
                leafletData.getMap().then(function(map) {
                    map.options.crs = crs;
                    L.esri.tiledMapLayer(options).addTo(map);

                    // refresh view manually to reload tiles
                    map.setView([map.getCenter().lat, map.getCenter().lng], map.getZoom());
                });
            }
        }
    }
})();

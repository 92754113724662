(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('WebSocketController', webSocketController);

    webSocketController.$inject = ['$scope', '$websocket'];

    function webSocketController($scope, $websocket) {
        $scope.ws = {};
        $scope.received = [];
        $scope.statuses = [];
        $scope.actual = '';

        $scope.send = send;

        initController();

        function initController() {
            var scheme = document.location.protocol === 'https:' ? 'wss' : 'ws';
            var port = document.location.port ? (':' + document.location.port) : '';
            var url = scheme + "://" + document.location.hostname + port + '/ws';

            $scope.ws = $websocket.$new({
                url: url,
                protocols: []
            });

            $scope.ws.$on('$open', function () {
                $scope.statuses.push('OPEN');
            });

            $scope.ws.$on('$close', function () {
                $scope.statuses.push('CLOSE');
            });

            $scope.ws.$on('ping', function () {
                $scope.statuses.push('PING');
            });

            $scope.ws.$on('$message', function (data) {
                $scope.statuses.push('MESSAGE');
                
                $scope.received.push(data);
            });
        }

        function send() {
            $scope.ws.$emit("ping", $scope.actual);
            $scope.actual = '';
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('articlesService', articlesService);

    articlesService.$inject = ['$q', '$http', 'serviceUrls', 'cacheService'];

    function articlesService($q, $http, serviceUrls, cacheService) {

        const cacheKey = "_articles";

        var articlesService = {
            getArticles: getArticles
        };

        return articlesService;

        function getArticles(refresh) {
            var deferred = $q.defer();

            var query = refresh ? getServerArticles() : cacheService.get(cacheKey);

            query.then(
                function (articles) {
                    deferred.resolve(articles);
                },
                function(err) {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getServerArticles() {
            var deferred = $q.defer();
            var articles;

            $http.get(serviceUrls.articles).then(
                function (response) {
                    articles = response.data;
                    return cacheService.set(cacheKey, articles);
                },
                function (err) {
                    deferred.reject();
                }).then(function() {
                    deferred.resolve(articles);
            });


            return deferred.promise;
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('dynamicRouteListener', dynamicRouteListener);

    dynamicRouteListener.$inject = ['$rootScope', '$localForage', '$log'];

    function dynamicRouteListener($rootScope, $localForage, $log) {
        var connection;

        var dynamicRouteListener = {
            start: start,
            stop: stop
        };

        return dynamicRouteListener;

        function start(orderId) {
            stop();

            $localForage.getItem('authData').then(function (authData) {
                if (authData) {
                    var options = {
                        logging: signalR.LogLevel.Information,
                        accessTokenFactory: function () {
                            return authData.token;
                        }
                    };

                    connection = new signalR.HubConnectionBuilder()
                        .withUrl('routehub?customerid=' + authData.customerId + '&orderId=' + orderId, options)
                        .build();

                    connection.on("routeStopCompleted", function (data) {
                        $rootScope.$broadcast('updateReportedRoutes', data.reportedRoutes);
                    });

                    connection.start().catch(function (error) {
                        $log.warn('dynamicRouteListener.start()');
                    });
                }
            });
        }

        function stop() {
            if (connection) {
                connection.stop();
            }
        }
    }
})();

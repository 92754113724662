(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('MultipleChoiceModalController', multipleChoiceModalController);

    multipleChoiceModalController.$inject = [
        '$scope',
        '$windowInstance',
        'choices',
    ];

    function multipleChoiceModalController(
        $scope,
        $windowInstance,
        choices,
    ) {
        $scope.choices = choices;
        $scope.choose = choose;
        $scope.close = close;

        function close() {
            $windowInstance.close(false);
        }

        function choose(option) {
            $windowInstance.close(option);
        }
    }
})();

(function () {
    'use strict';

    let pwaPoCApp = angular.module('PWAPoCApp');

    pwaPoCApp.constant('formTypes', {
        add: 'add',
        edit: 'edit'
    });

    pwaPoCApp.constant('orderStatusTransitions', {
        start: 0,
        cancel: 1,
        complete: 2,
        delete: 3
    });

    pwaPoCApp.constant('messageState', {
        'new': 0,
        'read': 1,
        'readProfile': 2
    });

    pwaPoCApp.constant('orderDataDescriptions', {
        'DetailData_Name': 'Navn',
        'DetailData_Address1': 'Adresse 1',
        'DetailData_Address2': 'Adresse 2',
        'DetailData_PostalCode': 'Postnummer',
        'DetailData_Other': 'Annet',
        'DetailData_Message': 'Melding',
        'DetailData_OrderId': 'Ordre id',
        'DetailData_Expires': 'Utgår',
        'DetailData_LastDate': 'Siste dato',
        'DetailData_LastVolume': 'Siste volum',
        'DetailData_MessageSeptic': 'Merknad',
        'DetailData_Material': 'Materialtype',
        'DetailData_NumberOfChambers': 'Ant.kammer',
        'DetailData_TubeLength': 'Slangemeter',
        'DetailData_BackingRequired': 'Rygging',
        'DetailData_LengthFromRoad': 'Avstand vei',
        'DetailData_SmallestRoadWidth': 'Smaleste vei',
        'DetailData_Status': 'Status',
        'DetailData_Year': 'År'
    });
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('orderMenu', orderMenu);

    orderMenu.$inject = [];

    function orderMenu() {
        var directive = {
            restrict: 'E',
            scope: {
                close: '&ngClickClose',
                logout: '&ngClickLogout',
                settings: '&ngClickSettings',
                support: '&ngClickSupport'
            },
            transclude: true,
            templateUrl: 'app/shared/directives/order-menu.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var mobileDetect = new MobileDetect(window.navigator.userAgent);
            scope.fadeModal = !mobileDetect.is('AndroidOS');
        }
    }
})();

(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('orderDetailTypesService', orderDetailTypesService);

    orderDetailTypesService.$inject = ['$q', '$rootScope', '$http', 'cacheService', 'serviceUrls'];

    function orderDetailTypesService($q, $rootScope, $http, cacheService, serviceUrls) {
        var cacheKey = '_orderDetailTypes';
        var selectedCacheKey = '_selectedOrderDetailTypes';

        var orderDetailTypesService = {
            clearSelectedOrderDetailTypes: clearSelectedOrderDetailTypes,
            getOrderDetailTypes: getOrderDetailTypes,
            getSelectedOrderDetailTypes: getSelectedOrderDetailTypes,
            setSelectedOrderDetailTypes: setSelectedOrderDetailTypes,
            setOrderDetailTypeWndWasOpened: setOrderDetailTypeWndWasOpened
        };

        return orderDetailTypesService;

        function setOrderDetailTypeWndWasOpened() {
            cacheService.get('orderDetailTypesWndWasOpened').then(function (odtValue) {
                if (odtValue !== 'true') {
                    cacheService.set('orderDetailTypesWndWasOpened', 'true');
                    $rootScope.$broadcast('orderDetailTypesCountFinished');
                }
            });
        }

        function clearSelectedOrderDetailTypes() {
            return setSelectedOrderDetailTypes([]);
        }

        function getOrderDetailTypes(refreshCache) {
            var deferred = $q.defer();

            if (!refreshCache) {
                cacheService.has(cacheKey)
                    .then(function (exists) {
                        if (exists) {
                            return cacheService.get(cacheKey);
                        } else {
                            return getServerOrderDetailTypes();
                        }
                    })
                    .then(function (orderDetailTypes) {
                        deferred.resolve(orderDetailTypes);
                    })
                    .catch(function () {
                        deferred.reject();
                    });
            } else {
                getServerOrderDetailTypes().then(function (orderDetailTypes) {
                    deferred.resolve(orderDetailTypes);
                }, function () {
                    deferred.reject();
                });
            }

            return deferred.promise;
        }

        function getSelectedOrderDetailTypes() {
            var deferred = $q.defer();

            cacheService.get(selectedCacheKey).then(function (selectedOrderDetailTypes) {
                deferred.resolve(selectedOrderDetailTypes);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function setSelectedOrderDetailTypes(selectedOrderDetailTypeIds) {
            var deferred = $q.defer();

            cacheService.set(selectedCacheKey, selectedOrderDetailTypeIds).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        // private functions
        function getServerOrderDetailTypes() {
            var deferred = $q.defer();

            var orderDetailTypes;
            $http.get(serviceUrls.orderDetailTypes)
                .then(function (response) {
                    orderDetailTypes = response.data;
                    return cacheService.set(cacheKey, orderDetailTypes);
                })
                .then(function () {
                    deferred.resolve(orderDetailTypes);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }
    }
})();

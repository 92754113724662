(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('numericInput', numericInput);

    numericInput.$inject = [];


    function numericInput() {
        return {
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl) {
                function fromUser(text) {
                    if (text) {
                        if (text[0].match(/[0-9]/g) !== null) {
                            var transformedInput = text.replace(/[^0-9,]/g, '');

                            if (transformedInput !== text) {
                                ngModelCtrl.$setViewValue(transformedInput);
                                ngModelCtrl.$render();
                            } 
                            return transformedInput;
                        } else {
                            ngModelCtrl.$setViewValue("");
                            ngModelCtrl.$render();
                            return "";
                        }
                    }
                    return undefined;
                }            
                ngModelCtrl.$parsers.push(fromUser);
            }
        };
    }

})();
